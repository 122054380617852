import React from 'react';

import getConfig from 'next/config';
import Script from 'next/script';

import { track } from '@hbf/analytics';

import { AnalyticsEvent } from '../Analytics/types';
import { getUnlessExperimentStartedEventPayload } from '../Analytics/utilities';

import { UnlessEventDetail } from './types';

const { publicRuntimeConfig } = getConfig();

export const Unless = () => {
  const unlessId = publicRuntimeConfig.UNLESS_ID;

  React.useEffect(() => {
    if (!unlessId) {
      return;
    }

    document.addEventListener('unless-event', e => {
      const detail = (e as CustomEvent).detail as UnlessEventDetail;
      if (detail?.event_type === 'experience_started') {
        track(
          AnalyticsEvent.UnlessExperimentStarted,
          getUnlessExperimentStartedEventPayload(
            detail.experience_name,
            detail.experience_test,
          ),
        );
      }
    });
  }, [unlessId]);

  if (!unlessId) {
    return null;
  }

  return (
    <Script
      // eslint-disable-next-line custom/no-restricted-translation-jsx-untranslated-prop-text
      data-unless="1.2"
      strategy="afterInteractive"
      data-installer="snippet"
      id="unless-init"
    >
      {`
        !function(o,p,t,i,m,a,l){function s(e){/* o.documentElement.style.opacity=e; this line is disabled because we don't want the loading indicator of unless */ return;}function d(e){i.parentNode.insertBefore(e,i)}s((window.TxtOptions||{}).asyncMode?"":0),setTimeout(function(){s("")},3e3),i=(o.head||o.documentElement).firstChild,l="https://"+t+".unless.com/js/v5/latest/txt.min.js?id="+t+"&domain="+window.location.hostname,(m=o.createElement("link")).href=l,m.rel="preconnect preload",m.as=p,d(m),(a=o.createElement(p)).src=l,d(a),a.onerror=function(){s("")}}
        (document,"script","${unlessId}");
      `}
    </Script>
  );
};
