/**
 *
 */
export enum StripePaymentMethodType {
  Ideal = 'ideal',
  Card = 'card',
  Paypal = 'paypal',
  SepaDebit = 'sepa_debit',
  GooglePay = 'google_pay',
  ApplePay = 'apple_pay',
}
