/**
 *
 */
export enum RoommateNumberID {
  None = 1,
  One = 2,
  Two = 4,
  Three = 8,
  Four = 16,
  Five = 32,
  Six = 64,
  Seven = 128,
  Eight = 256,
  MoreThanEight = 512,
  NotRelevant = 1024,
}
