/* eslint-disable no-param-reassign */
import { getCookieValue, setCookieValue } from '@kamernet/utilities/Cookies';
import {
  AnalyticsUserProperties,
  AnalyticsCookie,
  AnalyticsSession,
} from '../types';

let urlSearchParams: URLSearchParams | null = null;

export function getParamFromUrl(query: string) {
  if (!urlSearchParams) {
    urlSearchParams = new URLSearchParams(window.location.search);
  }

  return urlSearchParams.get(query) || '';
}

export function setUtmEventData(userProperties: AnalyticsUserProperties) {
  const source = getParamFromUrl('utm_source');
  const medium = getParamFromUrl('utm_medium');
  const term = getParamFromUrl('utm_term');
  const content = getParamFromUrl('utm_content');
  const campaign = getParamFromUrl('utm_campaign');

  if (source) {
    userProperties.utm_source = source;
  }

  if (medium) {
    userProperties.utm_medium = medium;
  }

  if (term) {
    userProperties.utm_term = term;
  }

  if (content) {
    userProperties.utm_content = content;
  }

  if (campaign) {
    userProperties.utm_campaign = campaign;
  }
  return userProperties;
}

export function getCurrentSource(): string {
  const source = getParamFromUrl('utm_source');
  const medium = getParamFromUrl('utm_medium');
  const ref = window.document.referrer;

  if (source || medium) {
    let result = '';
    if (source) {
      result = source;
    }
    if (medium) {
      if (result === '') {
        result = medium;
      } else {
        result = `${result}/${medium}`;
      }
    }
    return result;
  }

  if (
    ref === '' ||
    ref === undefined ||
    ref.match(/https?:\/\/(.*)kamernet.nl/) !== null
  ) {
    return 'Direct';
  }

  const googleSites = [
    /https?:\/\/(.*)google.([^/?]*)/,
    /(.*)com.google.android.googlequicksearchbox(.*)/,
    /(.*)com.google.android.gm(.*)/,
  ];

  if (googleSites.some(site => ref.match(site) !== null)) {
    return 'Google';
  }

  const otherSearchEngines = [
    /https?:\/\/(.*)bing\.([^/?]*)/,
    /https?:\/\/(.*)yahoo.([^/?]*)/,
    /https?:\/\/(.*)baidu.([^/?]*)/,
    /https?:\/\/(.*)yandex.([^/?]*)/,
    /https?:\/\/(.*)ecosia.org/,
    /https?:\/\/(.*)duckduckgo.([^/?]*)/,
  ];

  if (otherSearchEngines.some(engine => ref.match(engine) !== null)) {
    return 'Other search';
  }

  const portals = [
    /https?:\/\/(.*)studyinsweden.se/,
    /https?:\/\/(.*)studyindenmark.dk/,
    /https?:\/\/(.*)studyintorino.it/,
    /https?:\/\/(.*)iamsterdam.com/,
    /https?:\/\/(.*)educationinireland.com/,
    /https?:\/\/(.*)studyinfinland.fi/,
    /https?:\/\/(.*)fyidenmark.com/,
    /https?:\/\/(.*)studyinaustria.at/,
  ];

  if (portals.some(portal => ref.match(portal) !== null)) {
    return 'Portals';
  }

  if (ref.match(/https?:\/\/(.*)youtube.com/) !== null) {
    return 'Youtube';
  }

  if (ref.match(/https?:\/\/(.*)live.com/) !== null) {
    return 'Email';
  }

  const feeds = [
    /https?:\/\/(.*)justlanded.([^/?]*)/,
    /https?:\/\/(.*)trovit.([^/?]*)/,
  ];

  if (feeds.some(feed => ref.match(feed) !== null)) {
    return 'Feeds';
  }

  if (ref.match(/https?:\/\/(.*)nestpick.([^/?]*)/) !== null) {
    return 'Nestpick';
  }

  if (ref.match(/([a-z-]+):\/\/(.*).facebook.(.*)/) !== null) {
    return 'Facebook';
  }

  if (ref.match(/https?:\/\/(.*)twitter.([^/?]*)/) !== null) {
    return 'Twitter';
  }

  if (ref.includes('.edu')) {
    return 'University domains';
  }

  return 'Other';
}

export function setSourceData(userProperties: AnalyticsUserProperties) {
  // If no session for currentSource is found, initialize it.
  // It will hold the value of currentSource for the entire session
  let currentSource = sessionStorage.getItem(AnalyticsSession.CurrentSource);

  if (!currentSource) {
    currentSource = getCurrentSource();
    sessionStorage.setItem(AnalyticsSession.CurrentSource, currentSource);
  }

  // Get initial source. If none found, intialize to current source.
  let source = getCookieValue(AnalyticsCookie.FirstSource);
  if (!source) {
    source = currentSource;
    setCookieValue(AnalyticsCookie.FirstSource, currentSource, 30);
  }

  userProperties.source = source;
  userProperties.currentSource = currentSource;
  userProperties.path = window.location.pathname;

  return userProperties;
}

export function setSubscriptionType(
  userProperties: AnalyticsUserProperties,
  membership: string,
) {
  userProperties.subscription_type = membership;

  return userProperties;
}

export function getUserProperties(activeMembershipName: string | null) {
  const userProperties = {};

  if (activeMembershipName) {
    setSubscriptionType(userProperties, activeMembershipName);
  }

  setUtmEventData(userProperties);
  setSourceData(userProperties);
  return userProperties;
}
