import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig() || {};

const PRODUCTION_KAMERNET_API_ORIGIN = 'https://kamernet.nl/services/api';

export function isUsingProductionApi() {
  return (
    publicRuntimeConfig?.KAMERNET_API_ORIGIN === PRODUCTION_KAMERNET_API_ORIGIN
  );
}
export function isAcceptance() {
  return (
    process.env.NODE_ENV === 'production' &&
    publicRuntimeConfig?.DEPLOYMENT_ID.startsWith('acceptance')
  );
}

export function isProduction() {
  return (
    process.env.NODE_ENV === 'production' &&
    publicRuntimeConfig?.DEPLOYMENT_ID === 'production'
  );
}

export function isDevelopment() {
  return (
    process.env.NODE_ENV === 'development' ||
    publicRuntimeConfig?.DEPLOYMENT_ID === 'local'
  );
}

export function isAcceptanceOrProduction() {
  return isAcceptance() || isProduction();
}
