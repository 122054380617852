/**
 *
 */
export enum OpResponseCode {
  OPERATION_SUCCESSFUL = 1000,
  USER_HAS_ACTIVE_MEMBERSHIP = 1115,
  GENERAL_ERROR = 1078,
  PAYMENT_PROCESSING_FAILED = 1116,
  TRANSCATION_NOT_FOUND_FOR_PAYMENT = 1120,
  BUKAROO_TRANSCATION_CANCELLED = 1122,
  BUKAROO_TRANSCATION_REFUSED = 1123,
  BUKAROO_TRANSCATION_PENDING = 1124,
  USER_HAS_OPEN_INVOICE = 1125,
  BUCKAROO_PAYMENT_PROCESSING_FAILED = 1127,
  BUCKAROO_PAYMENT_STILL_PROCESSING = 1128,
  SEARCH_ALERT_SAVED_ALREADY = 1129,
  ADVERT_NOT_FOUND = 1015,
  PAYMENT_SESSION_EXPIRED = 1135,
  LCF_NL_FORBIDDEN_TEXT = 10064,
  LCF_EN_FORBIDDEN_TEXT = 10065,
}
