import { ApiObject } from '@hbf/analytics';

import { Locale } from '@kamernet/core/Intl/types';

import {
  AnalyticsPageCategory,
  AnalyticsPageName,
  AnalyticsUserProperties,
} from '../types';

export function getPageViewedEventPayload(
  locale: string,
  pageName?: AnalyticsPageName,
  pageCategory?: AnalyticsPageCategory,
  pageProperties?: Record<string, unknown>,
  userProperties?: AnalyticsUserProperties,
): ApiObject {
  return {
    kamernetApp: 'next',
    language: locale === Locale.en ? 'English' : 'Dutch',
    ...(pageName && { name: pageName }),
    ...(pageCategory && { category: pageCategory }),
    ...pageProperties,
    ...userProperties,
  };
}
