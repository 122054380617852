import { AppContext } from 'next/app';

import { serialize } from 'cookie';
import { v4 as generateUUID } from 'uuid';

import { reportError } from '@kamernet/core/Errors';
import { CustomTags, ErrorModules } from '@kamernet/core/Errors/types';

import { AppCookieName } from './types';

const ANONYMOUS_ID_COOKIE_LIFE_TIME = 365 * 24 * 3600 * 1000; // 1 year

export function initAppCookiesServerApp(appContext: AppContext): void {
  try {
    const req = appContext.ctx.req!;
    const res = appContext.ctx.res!;

    if (!req.cookies[AppCookieName.ANONYMOUS_ID]) {
      req.cookies[AppCookieName.ANONYMOUS_ID] = generateUUID();
    }

    res.appendHeader(
      'Set-Cookie',
      serialize(
        AppCookieName.ANONYMOUS_ID,
        req.cookies[AppCookieName.ANONYMOUS_ID],
        {
          path: '/',
          expires: new Date(Date.now() + ANONYMOUS_ID_COOKIE_LIFE_TIME),
          httpOnly: true,
          secure: true,
          sameSite: 'lax',
        },
      ),
    );
  } catch (error) {
    reportError(new Error('Failed to init app cookies'), {
      extra: {
        cause: error,
      },
      tags: {
        [CustomTags.KN_Module]: ErrorModules.InitAppCookies,
      },
    });
  }
}
