import 'normalize.css';

import '@kamernet/styles/GlobalStyles.css';
import '@kamernet/styles/theme.design.css';
import '@kamernet/styles/theme.mui.css';

import React from 'react';

import NextApp, {
  AppProps as NextAppProps,
  AppContext as NextAppContext,
  NextWebVitalsMetric,
} from 'next/app';
import getConfig from 'next/config';
import Head from 'next/head';

import { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Translations } from '@ha/intl';
import { identify, page, track } from '@hbf/analytics';
import { IntegrationNames } from '@hbf/analytics/lib/constants';

import { HttpStatus } from '@kamernet/constants/HttpStatus';
import { AffiliatesSetup } from '@kamernet/core/Affiliates';
import { Hotjar } from '@kamernet/core/Analytics/Hotjar';
import {
  AnalyticsEvent,
  AnalyticsPageCategory,
  AnalyticsPageName,
  AnalyticsPageProps,
  AnalyticsUserProperties,
} from '@kamernet/core/Analytics/types';
import {
  getPageViewedEventPayload,
  getPageEventPayload,
  getUserProperties,
} from '@kamernet/core/Analytics/utilities';
import { AppServicesProvider } from '@kamernet/core/AppServices';
import { AuthProvider } from '@kamernet/core/Auth/AuthProvider';
import {
  GoogleOneTap,
  RecaptchaEnterprise,
} from '@kamernet/core/Auth/components';
import { initAuthBrowserApp } from '@kamernet/core/Auth/initAuthBrowserApp';
import { initAuthServerApp } from '@kamernet/core/Auth/initAuthServerApp';
import { IAuthState } from '@kamernet/core/Auth/types';
import { bootstrapServer } from '@kamernet/core/Bootstrap/bootstrapServer';
import { CommonMessages } from '@kamernet/core/CommonMessages';
import { initAppCookiesServerApp } from '@kamernet/core/Cookie/initAppCookiesServerApp';
import {
  setCustomErrorTag,
  setUserContextForErrorReporting,
} from '@kamernet/core/Errors';
import { ErrorBoundary } from '@kamernet/core/Errors/ErrorBoundary';
import { CustomTags, ErrorModules } from '@kamernet/core/Errors/types';
import { initFeatureFlagsBrowserApp } from '@kamernet/core/FeatureFlags/initFeatureFlagsBrowserApp';
import {
  initSessionFeatureFlagsServerApp,
  initUserFeatureFlagsServerApp,
} from '@kamernet/core/FeatureFlags/initFeatureFlagsServerApp';
import { FeatureFlags } from '@kamernet/core/FeatureFlags/types';
import { IntlProvider, createIntl } from '@kamernet/core/Intl';
import { Locale } from '@kamernet/core/Intl/types';
import { logger } from '@kamernet/core/Logger';
import { createMuiDateLocalizationAdapter } from '@kamernet/core/MUI/MuiDateLocalizationAdapter';
import {
  PUBLIC_ROUTES,
  ROUTES,
  getRouteByPathname,
} from '@kamernet/core/Routing';
import { ServiceWorkerSetup } from '@kamernet/core/ServiceWorker';
import { Unless } from '@kamernet/core/Unless';
import { createEmotionCache } from '@kamernet/styles/createEmotionCache';
import { makeTheme } from '@kamernet/styles/theme.mui';
import { getDeviceType } from '@kamernet/utilities/UserAgent';
import { DeviceType } from '@kamernet/utilities/UserAgent/types';

import { FlushAnonymousPSA } from '@kamernet/modules/Listing/components/FlushAnonymousPSA';

import { loadLocale } from '../locales';

const { publicRuntimeConfig } = getConfig();

const clientSideEmotionCache = createEmotionCache();

function sendRudderstackPageEvent(
  pageProps: PageProps,
  locale: Locale,
  userProperties: AnalyticsUserProperties,
): void {
  const pageEventPayload = getPageEventPayload(
    locale,
    pageProps.analytics?.properties,
    userProperties,
  );

  page(
    pageProps.analytics?.category || AnalyticsPageCategory.Other,
    pageProps.analytics?.name || AnalyticsPageName.Other,
    pageEventPayload,
    {
      integrations: {
        [IntegrationNames.Mixpanel]: false,
      },
    },
  );
}

/**
 * This event is only used for Mixpanel because Kamernet tracks page viewed events as 'PageViewed'. This is why we can't use sendRudderstackPageEvent(), which uses "Loaded a page" instead
 */
function sendMixpanelPageEvent(
  pageProps: PageProps,
  locale: Locale,
  userProperties: AnalyticsUserProperties,
): void {
  const pageViewedEventPayload = getPageViewedEventPayload(
    locale,
    pageProps.analytics?.name,
    pageProps.analytics?.category,
    pageProps.analytics?.properties,
    userProperties,
  );

  track(AnalyticsEvent.PageViewed, pageViewedEventPayload, {
    integrations: { All: false, [IntegrationNames.Mixpanel]: true },
  });
}

type PageProps = AnalyticsPageProps;

export interface AppProps extends NextAppProps<PageProps> {
  emotionCache?: EmotionCache;
  messages: Translations;
  authState: IAuthState;
  featureFlags: FeatureFlags;
  pageProps: PageProps;
  deviceType: DeviceType;
}

const App = ({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
  deviceType,
  authState,
  featureFlags,
  messages,
  router,
}: AppProps) => {
  const intl = React.useMemo(() => {
    const locale = Locale[router.locale as Locale] || Locale.nl;

    return createIntl({
      locale,
      messages,
    });
  }, [messages, router.locale]);

  const { T, locale } = intl;
  const dateAdapter = React.useMemo(
    () => createMuiDateLocalizationAdapter(),
    [],
  );
  const theme = React.useMemo(
    () => makeTheme(locale, deviceType),
    [deviceType, locale],
  );

  const userId = authState.userProfile?.userid;
  const userMembershipName = authState?.userProfile?.productshortdescription;

  React.useEffect(() => {
    const userProperties = getUserProperties(userMembershipName ?? null);
    setCustomErrorTag(CustomTags.KN_Module, ErrorModules.AppBootstrap);
    if (userId) {
      identify(`u${userId}`, userProperties);
      if (authState.userProfile) {
        setUserContextForErrorReporting(
          authState.userProfile,
          locale,
          authState.anonymousId,
        );
      }
    }

    sendMixpanelPageEvent(pageProps, locale, userId ? {} : userProperties);
    sendRudderstackPageEvent(pageProps, locale, userId ? {} : userProperties);
  }, [pageProps, userId, locale, userMembershipName, authState]);

  return (
    <ErrorBoundary T={T} locale={locale}>
      <React.Fragment>
        <Head>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
        </Head>

        <RecaptchaEnterprise />
        <Hotjar />
        <Unless />

        <IntlProvider value={intl}>
          <Head>
            <title>{T(CommonMessages.app_name)}</title>
            <meta
              name="apple-mobile-web-app-title"
              content={T(CommonMessages.app_name)}
            />
            <meta
              name="application-name"
              content={T(CommonMessages.app_name)}
            />
          </Head>

          <AppServicesProvider featureFlags={featureFlags}>
            <ServiceWorkerSetup />
            <AffiliatesSetup />
            <AuthProvider authState={authState}>
              <FlushAnonymousPSA />
              {!authState.userProfile && <GoogleOneTap />}
              <CacheProvider value={emotionCache}>
                <ThemeProvider theme={theme}>
                  <StyledEngineProvider injectFirst>
                    <LocalizationProvider
                      dateAdapter={dateAdapter}
                      adapterLocale={locale}
                    >
                      <Component {...pageProps} />
                    </LocalizationProvider>
                  </StyledEngineProvider>
                </ThemeProvider>
              </CacheProvider>
            </AuthProvider>
          </AppServicesProvider>
        </IntlProvider>
      </React.Fragment>
    </ErrorBoundary>
  );
};

// opt-out of Automatic Static Optimization
App.getInitialProps = async (
  appContext: NextAppContext,
): Promise<
  Pick<
    AppProps,
    'messages' | 'authState' | 'pageProps' | 'featureFlags' | 'deviceType'
  >
> => {
  const { ctx } = appContext;
  const locale = Locale[ctx.locale as Locale] || Locale.nl;
  const route = ctx.pathname;
  const startTime = Date.now();

  logger.info({
    message: `_app: processing route ${route} on ${
      ctx.req ? 'server' : 'browser'
    } ; started`,
    route,
    locale,
    query: ctx.query,
  });

  if (typeof window === 'undefined') {
    await bootstrapServer();
  }

  const deviceType: DeviceType = appContext.ctx.req
    ? getDeviceType(appContext.ctx.req.headers['user-agent'])
    : appContext.router.components[appContext.router.route]?.props
        ?.deviceType || 'desktop';

  let messages: Record<string, string> = {};

  if (ctx.req) {
    initAppCookiesServerApp(appContext);
  }

  const sessionFeatureFlags = ctx.req
    ? await initSessionFeatureFlagsServerApp(appContext)
    : initFeatureFlagsBrowserApp(appContext);

  const authState = ctx.req
    ? await initAuthServerApp(appContext, sessionFeatureFlags)
    : initAuthBrowserApp(appContext);

  const userFeatureFlags = ctx.req
    ? await initUserFeatureFlagsServerApp(appContext)
    : initFeatureFlagsBrowserApp(appContext);

  const featureFlags = {
    ...userFeatureFlags,
    ...sessionFeatureFlags,
  };

  logger.info({
    message: `_app: processing route ${route} ; initialized auth state`,
    route,
    locale,
    query: ctx.query,
    duration: Date.now() - startTime,
  });

  const initialProps = await NextApp.getInitialProps(appContext);

  logger.info({
    message: `_app: processing route ${route} ; loaded props`,
    route,
    locale,
    query: ctx.query,
    initialProps,
    duration: Date.now() - startTime,
  });

  const duration = Date.now() - startTime;

  if (ctx.req && duration > 800) {
    logger.warn({
      message: `_app: processing route ${route} ; loaded props with degraded performance`,
      route,
      locale,
      query: ctx.query,
      duration,
    });
  }

  function redirect(url: string) {
    logger.info({
      message: `_app: processing route ${route} ; done ; redirecting from "${ctx.asPath}" to "${url}"`,
      duration: Date.now() - startTime,
    });

    if (ctx.req) {
      ctx.res!.writeHead(HttpStatus.TEMPORARY_REDIRECTION, {
        Location: url,
      });
      ctx.res!.end();
    } else {
      window.location.assign(url);
    }

    return {
      messages: {},
      authState,
      featureFlags,
      pageProps: {},
      deviceType,
    };
  }

  if (!route.startsWith('/_') && route !== '/404' && route !== '/500') {
    if (!authState.userProfile && !PUBLIC_ROUTES.includes(route)) {
      const prevUrl =
        publicRuntimeConfig.ORIGIN + ROUTES.Home.url({}, locale, 'prefix');

      const signinUrl =
        featureFlags.use_idp === 'on'
          ? ROUTES.Signin.url(
              {
                ...(getRouteByPathname(route)?.isLandlordRoute && {
                  isUserLandlord: 'true',
                }),
                prevUrl,
              },
              locale,
            )
          : ROUTES.LegacyLogin.url(
              {
                returnUrl: ctx.asPath,
              },
              locale,
              'prefix',
            );

      return redirect(signinUrl);
    }
  }

  if (route !== '/_error') {
    messages = await loadLocale(locale);
  }

  logger.info({
    message: `_app: processing route ${route} ; done`,
    route,
    locale,
    query: ctx.query,
    initialProps,
    duration: Date.now() - startTime,
  });

  return {
    messages,
    authState,
    featureFlags,
    ...initialProps,
    deviceType,
  };
};

export function reportWebVitals(metric: NextWebVitalsMetric) {
  logger.info({
    message: `Reporting web vital metric ${metric.name}`,
    ...metric,
  });

  const { label, name, value, id } = metric;

  track(
    name,
    {
      category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      kamernetApp: 'next',
      name,
      value: Math.round(name === 'CLS' ? value * 1000 : value),
      event_label: id,
      non_interaction: true,
    },
    {
      event_label: id,
      non_interaction: true,
    },
  );
}

export default App;
