import { ApiObject } from '@hbf/analytics';

import { Locale } from '@kamernet/core/Intl/types';

import { AnalyticsUserProperties } from '../types';

export function getPageEventPayload(
  locale: string,
  pageProperties?: Record<string, unknown>,
  userProperties?: AnalyticsUserProperties,
): ApiObject {
  return {
    kamernetApp: 'next',
    language: locale === Locale.en ? 'English' : 'Dutch',
    ...pageProperties,
    ...userProperties,
  };
}
