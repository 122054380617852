/**
 *
 */
export enum LanguageID {
  DUTCH = 1,
  ENGLISH = 2,
  SPANISH = 4,
  FRENCH = 8,
  ITALIAN = 16,
  GERMAN = 32,
  PORTUGUESE = 64,
  CHINESE = 128,
  RUSSIAN = 256,
  JAPANESE = 512,
  HINDI = 1024,
  BENGALI = 2048,
  KOREAN = 4096,
  TURKISH = 8192,
  VIETNAMESE = 16384,
  POLISH = 32768,
  ARABIC = 65536,
  ROMANIAN = 131072,
}
