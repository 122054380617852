export type DefaultTreatments = 'on' | 'off';

export type UserFeatureFlags = Readonly<{
  // ...
}>;

export type SessionFeatureFlags = Readonly<{
  use_idp: DefaultTreatments;
  show_buy_premium_burger_menu_desktop: DefaultTreatments;
}>;

export type FeatureFlags = UserFeatureFlags & SessionFeatureFlags;

export const DEFAULT_SESSION_FEATURE_FLAGS: SessionFeatureFlags = {
  use_idp: 'on',
  show_buy_premium_burger_menu_desktop: 'on',
};

export const DEFAULT_USER_FEATURE_FLAGS: UserFeatureFlags = {
  // ...
};

export const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
  ...DEFAULT_USER_FEATURE_FLAGS,
  ...DEFAULT_SESSION_FEATURE_FLAGS,
};
