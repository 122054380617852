import React from 'react';

import { useRouter } from 'next/router';

import { v4 as generateId } from 'uuid';

import {
  DAISYCON_UTM_SOURCE,
  NESTPICK_API_BASE_URL,
  NESTPICK_UTM_SOURCE,
} from '@kamernet/constants/Affiliates';
import { useLocalStorage } from '@kamernet/core/BrowserStorage';
import { DaisyconContext } from '@kamernet/core/BrowserStorage/types';
import { reportError } from '@kamernet/core/Errors';

import { DaisyconEvent } from '../ApiClient/Kamernet';
import { AppServicesContext } from '../AppServices';

export const useAffiliates = () => {
  const [daisyconContext, storeDaisyconContext, isDaisyconContextReady] =
    useLocalStorage('affliateDataSource');

  const [nestpickContext, storeNextpickContext, isNestpickContextReady] =
    useLocalStorage('km-affiliate-tracking');

  const { apiClient } = AppServicesContext.useContext();
  const routerQuery = useRouter().query;

  const trackNestpickPurchaseEvent = React.useCallback(
    (paymentIntentId: string, amount: number) => {
      try {
        if (
          nestpickContext &&
          nestpickContext.utmSource === NESTPICK_UTM_SOURCE &&
          nestpickContext.transactionId &&
          !nestpickContext.hasSentPostback &&
          nestpickContext.expirationDate &&
          new Date(nestpickContext.expirationDate) > new Date()
        ) {
          const url = `${NESTPICK_API_BASE_URL}/aff_lsr?offer_id=164&transaction_id=${nestpickContext.transactionId}&amount=${amount}&adv_sub=${paymentIntentId}`;
          fetch(url)
            .then(response => {
              if (response.ok) {
                nestpickContext.hasSentPostback = true;
                storeNextpickContext(nestpickContext);
              }
            })
            .catch(ex => {
              reportError(ex as Error, {
                extra: {
                  context: 'Error calling Nestpick API to track purchase event',
                  metaData: { ex },
                },
              });
            });
        }
      } catch (ex) {
        reportError(ex as Error, {
          extra: {
            context: 'Error tracking purchase event for Nestpick',
            metaData: { ex },
          },
        });
      }
    },
    [nestpickContext, storeNextpickContext],
  );

  const setupNestpick = React.useCallback(() => {
    try {
      if (isNestpickContextReady && !nestpickContext) {
        const utmSource = (
          (routerQuery.utm_source ?? '') as string
        ).toLowerCase();

        const transactionId =
          utmSource === NESTPICK_UTM_SOURCE
            ? ((routerQuery.nestpick_transaction_id ?? '') as string)
            : '';

        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 30);

        storeNextpickContext({
          utmSource,
          transactionId,
          expirationDate: expirationDate.toISOString(),
          hasSentPostback: false,
        });
      }
    } catch (ex) {
      reportError(ex as Error, {
        extra: {
          context: 'Error setting up Nestpick',
          metaData: { ex },
        },
      });
    }
  }, [
    isNestpickContextReady,
    nestpickContext,
    routerQuery.nestpick_transaction_id,
    routerQuery.utm_source,
    storeNextpickContext,
  ]);

  const trackDaisyconEvent = React.useCallback(
    (event: DaisyconEvent) => {
      try {
        if (
          daisyconContext &&
          daisyconContext.utm_source === DAISYCON_UTM_SOURCE &&
          daisyconContext.clickId &&
          daisyconContext.transactionId &&
          daisyconContext.storedDateTime
        ) {
          const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
          const timestampThirtyDaysAgo = new Date().getTime() - thirtyDaysInMs;

          const storedDateTime = new Date(
            daisyconContext.storedDateTime,
          ).getTime();

          if (storedDateTime > timestampThirtyDaysAgo) {
            apiClient.affiliates
              .trackDaisyconEvent({
                trackDaisyconEventPayload: {
                  transactionType: event,
                  context: {
                    transactionId: daisyconContext.transactionId,
                    clickId: daisyconContext.clickId,
                  },
                },
              })
              .catch(ex => {
                reportError(ex as Error, {
                  extra: {
                    context: 'Error calling trackDaisyconEvent API',
                    metaData: { ex },
                  },
                });
              });
          }
        }
      } catch (ex) {
        reportError(ex as Error, {
          extra: {
            context: 'Error tracking Daisycon event',
            metaData: { ex },
          },
        });
      }
    },
    [apiClient.affiliates, daisyconContext],
  );

  const setupDaisycon = React.useCallback(() => {
    try {
      if (!isDaisyconContextReady) {
        return;
      }

      const utmSource = (
        (routerQuery.utm_source ?? '') as string
      ).toLowerCase();
      let resetAffiliateLocalStorage = true;

      if (utmSource) {
        if (daisyconContext?.storedDateTime) {
          const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
          const timestampThirtyDaysAgo = new Date().getTime() - thirtyDaysInMs;

          const storedDateTime = new Date(
            daisyconContext.storedDateTime,
          ).getTime();

          if (storedDateTime > timestampThirtyDaysAgo) {
            resetAffiliateLocalStorage = false;
          }
        }

        if (resetAffiliateLocalStorage) {
          const newContext = {
            utm_source: utmSource,
            storedDateTime: new Date().toISOString(),
          } as DaisyconContext;

          if (utmSource === DAISYCON_UTM_SOURCE) {
            newContext.transactionId = generateId();
            const clickId = (routerQuery.clickid ?? '') as string;
            if (clickId) {
              newContext.clickId = clickId;

              apiClient.affiliates
                .saveDaisyconContext({
                  daisyconContext: {
                    transactionId: newContext.transactionId,
                    clickId: newContext.clickId,
                  },
                })
                .catch(ex => {
                  reportError(ex as Error, {
                    extra: {
                      context: 'Error at calling saveDaisyconContext API',
                      metaData: { ex },
                    },
                  });
                });
            }
          }

          storeDaisyconContext(newContext);
        }
      }

      if (routerQuery.gsreg || routerQuery.email) {
        trackDaisyconEvent(DaisyconEvent.Signup);
      }
    } catch (ex) {
      reportError(ex as Error, {
        extra: {
          context: 'Error setting up Daisycon',
          metaData: { ex },
        },
      });
    }
  }, [
    apiClient.affiliates,
    daisyconContext?.storedDateTime,
    isDaisyconContextReady,
    routerQuery.clickid,
    routerQuery.email,
    routerQuery.gsreg,
    routerQuery.utm_source,
    storeDaisyconContext,
    trackDaisyconEvent,
  ]);

  return {
    trackNestpickPurchaseEvent,
    setupNestpick,
    trackDaisyconEvent,
    setupDaisycon,
  };
};
