/**
 *
 */
export enum RoomAdvertSortOptionID {
  NewestFirst = 1,
  PriceAscending = 2,
  PriceDescending = 4,
  Surface = 5,
  Availability = 6,
  SocialConnectionsFirst = 7,
}
