/**
 *
 */
export enum ProductID {
  PremiumOneMonthLandlords = 241,
  Premium15DaysLandlords = 242,
  TopAdvertThreeDays = 245,
  TopAdvertSevenDays = 246,
  TopAdvertUnlimited = 247,
  UnlockAdvertThreeDays = 249,
  UnlockAdvertSevenDays = 250,
  UnlockAdvertUnlimited = 251,
  PremiumOneMonthNoRenewal = 238,
  FreshStart = 257,
  PremiumOneMonthTenant = 258,
  PremiumThreeMonthsTenant = 259,
}
