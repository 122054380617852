import type {
  DaisyconContext,
  TrackDaisyconEventPayload,
} from '../models/index';
import * as runtime from '../runtime';

export interface SaveDaisyconContextRequest {
  daisyconContext: DaisyconContext;
}

export interface TrackDaisyconEventRequest {
  trackDaisyconEventPayload: TrackDaisyconEventPayload;
}

/**
 *
 */
export class AffiliatesApi extends runtime.BaseAPI {
  /**
   */
  async saveDaisyconContextRaw(
    requestParameters: SaveDaisyconContextRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.daisyconContext == null) {
      throw new runtime.RequiredError(
        'daisyconContext',
        'Required parameter "daisyconContext" was null or undefined when calling saveDaisyconContext().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/affiliates/savedaisycondatasource`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.daisyconContext,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Saves Daisycon context
   */
  async saveDaisyconContext(
    requestParameters: SaveDaisyconContextRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.saveDaisyconContextRaw(requestParameters, initOverrides);
  }

  /**
   */
  async trackDaisyconEventRaw(
    requestParameters: TrackDaisyconEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.trackDaisyconEventPayload == null) {
      throw new runtime.RequiredError(
        'trackDaisyconEventPayload',
        'Required parameter "trackDaisyconEventPayload" was null or undefined when calling trackDaisyconEvent().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/affiliates/servertrackingfordaisycon`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.trackDaisyconEventPayload,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Track Daisycon event
   */
  async trackDaisyconEvent(
    requestParameters: TrackDaisyconEventRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.trackDaisyconEventRaw(requestParameters, initOverrides);
  }
}
