import type { LocationsResponse } from '../models/index';
import * as runtime from '../runtime';

export interface GetCitiesRequest {
  query: string;
}

export interface GetLocationsRequest {
  query: string;
}

/**
 *
 */
export class SearchApi extends runtime.BaseAPI {
  /**
   */
  async getCitiesRaw(
    requestParameters: GetCitiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<string>>> {
    if (requestParameters.query == null) {
      throw new runtime.RequiredError(
        'query',
        'Required parameter "query" was null or undefined when calling getCities().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.query != null) {
      queryParameters.query = requestParameters.query;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/search/cities`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse<any>(response);
  }

  /**
   * Search for listings.
   */
  async getCities(
    requestParameters: GetCitiesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<string>> {
    const response = await this.getCitiesRaw(requestParameters, initOverrides);
    return response.value();
  }

  /**
   */
  async getLocationsRaw(
    requestParameters: GetLocationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LocationsResponse>> {
    if (requestParameters.query == null) {
      throw new runtime.RequiredError(
        'query',
        'Required parameter "query" was null or undefined when calling getLocations().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.query != null) {
      queryParameters.query = requestParameters.query;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/search/locations`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Search for listings.
   */
  async getLocations(
    requestParameters: GetLocationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LocationsResponse> {
    const response = await this.getLocationsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }
}
