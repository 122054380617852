/**
 *
 */
export enum EnergyID {
  Unknown = 1,
  A = 2,
  B = 3,
  C = 4,
  D = 5,
  E = 6,
  F = 7,
  G = 8,
}
