import type { AddFavoriteListingResponse } from '../models/index';
import * as runtime from '../runtime';

export interface AddFavoriteListingRequest {
  id: number;
}

/**
 *
 */
export class FavoriteApi extends runtime.BaseAPI {
  /**
   */
  async addFavoriteListingRaw(
    requestParameters: AddFavoriteListingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AddFavoriteListingResponse>> {
    if (requestParameters.id == null) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter "id" was null or undefined when calling addFavoriteListing().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.id != null) {
      queryParameters.id = requestParameters.id;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/favorite`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Save listing to favorites
   */
  async addFavoriteListing(
    requestParameters: AddFavoriteListingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AddFavoriteListingResponse> {
    const response = await this.addFavoriteListingRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }
}
