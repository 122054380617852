import React from 'react';

import { useAffiliates } from '../useAffiliates';

export const AffiliatesSetup = () => {
  const { setupDaisycon, setupNestpick } = useAffiliates();
  React.useEffect(() => {
    setupDaisycon();
    setupNestpick();
  }, [setupDaisycon, setupNestpick]);
  return null;
};
