/**
 *
 */
export enum SurfaceMinimumID {
  M6 = 2,
  M8 = 3,
  M10 = 4,
  M12 = 5,
  M14 = 6,
  M16 = 7,
  M18 = 8,
  M20 = 9,
  M22 = 10,
  M24 = 11,
  M26 = 12,
  M28 = 13,
  M30 = 14,
  M32 = 15,
  M34 = 16,
  M36 = 17,
  M38 = 18,
  M40 = 19,
  M45 = 20,
  M50 = 21,
  M60 = 22,
  M70 = 23,
  M80 = 24,
  M90 = 25,
  M100 = 26,
}
