import type { UserAccount, UserProfile } from '../models/index';
import * as runtime from '../runtime';

/**
 *
 */
export class UserApi extends runtime.BaseAPI {
  /**
   */
  async getUserAccountRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserAccount>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/customer/viewaccount`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get user account.
   */
  async getUserAccount(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserAccount> {
    const response = await this.getUserAccountRaw(initOverrides);
    return response.value();
  }

  /**
   */
  async getUserProfileRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UserProfile>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/customer/view`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get user profile.
   */
  async getUserProfile(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UserProfile> {
    const response = await this.getUserProfileRaw(initOverrides);
    return response.value();
  }
}
