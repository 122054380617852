const headerFontFamily = [
  '"Open Sans"',
  'Helvetica',
  'Arial',
  'sans-serif',
].join(', ');

const bodyFontFamily = ['"Open Sans"', 'Helvetica', 'Arial', 'sans-serif'].join(
  ', ',
);

const common = {
  black: '#000000',
  white: '#FFF',
  transparentGray: 'rgba(255, 255, 255, 0.2)',
};

const text = {
  primary: '#00163F',
  secondary: '#989898',
};

const primary = {
  contrastText: '#FFF',
  dark: '#038F5D',
  main: '#00A56A',
  light: '#06B475',
  accent: '#E9F4F3',
};

const secondary = {
  contrastText: '#FFF',
  dark: '#020F28',
  main: '#00163F',
  light: '#213862',
  accent: '#455A71',
};

const tertiary = {
  contrastText: '#FFF',
  dark: '#5E4006',
  main: '#F8C051',
  light: '#FFD873',
  accent: '#FCF4DD',
};

const success = {
  contrastText: '#FFF',
  dark: '#005F1B',
  main: '#06721E',
  light: '#0A9E33',
  accent: '#E3FCE3',
};

const danger = {
  contrastText: '#FFF',
  dark: '#AC0400',
  main: '#CB302C',
  light: '#E94944',
  accent: '#FFE2E1',
};

const warning = {
  contrastText: '#FFF',
  dark: '#C75300',
  main: '#E86108',
  light: '#FC7D21',
  accent: '#FFE8D2',
};

const mono = {
  contrastText: '#FFF',
  dark: '#CEDBE3',
  main: '#E2E9EE',
  light: '#F2F7FB',
  accent: '#FAFDFF',
};

const neutral = {
  contrastText: '#FFF',
  main: '#000000',
  overlay: 'rgba(2, 15, 40, 0.5)',
  gray: '#99A3AC',
  light: '#FFFFFF',
};

const breakpoint = {
  xs: 0,
  sm: 600,
  md: 1024,
  lg: 1360,
  xl: 1680,
};

const borderRadius = {
  xs: '4px',
  sm: '8px',
  md: '16px',
  lg: '24px',
  xl: '32px',
};

const fontSize = {
  sm: '14px',
  md: '24px',
  lg: '40px',
};

export const DesignTokens = {
  bodyFontFamily,
  headerFontFamily,
  color: {
    common,
    text,
    primary,
    secondary,
    tertiary,
    success,
    danger,
    warning,
    mono,
    neutral,
  },
  breakpoint,
  borderRadius,
  fontSize,
};
