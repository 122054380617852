import * as runtime from '../runtime';

/**
 *
 */
export class DebugApi extends runtime.BaseAPI {
  /**
   */
  async alwaysOkRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/debug/alwaysok`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * An endpoint that always returns 200
   */
  async alwaysOk(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.alwaysOkRaw(initOverrides);
  }

  /**
   */
  async alwaysUnauthorizedRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/debug/alwaysunauthorized`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * An endpoint that always returns 401
   */
  async alwaysUnauthorized(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.alwaysUnauthorizedRaw(initOverrides);
  }

  /**
   */
  async authorizedRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/debug/authorized`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * An authorized endpoint
   */
  async authorized(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.authorizedRaw(initOverrides);
  }
}
