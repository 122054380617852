import { IncomingMessage } from 'http';

import { Enum } from '@ha/core/utilities';

import { AppCookieName } from './types';

export function readAppCookies(
  req: IncomingMessage,
): Record<string, string | null> {
  const cookiesList = Enum.values(AppCookieName);
  const cookies: Record<string, string | null> = {};
  for (const cookieName of cookiesList) {
    cookies[cookieName] = req.cookies[cookieName] || null;
  }
  return cookies;
}
