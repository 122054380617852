/**
 *
 */
export enum MyConversationsInboxType {
  Default = 0,
  All = 1,
  Favorite = 4,
  Trash = 9,
  ActiveListings = 15,
  InactiveListings = 16,
  Unreplied = 17,
}
