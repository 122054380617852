import getConfig from 'next/config';

import { logger } from '@kamernet/core/Logger';

import { ApiClientBase } from './ApiClientBase';
import {
  Configuration,
  Middleware,
  AlertApi,
  AuthApi,
  FavoriteApi,
  UserApi,
  SearchApi,
  ListingApi,
  PaymentApi,
  TenantApi,
  AffiliatesApi,
  GisApi,
  DebugApi,
  LCFApi,
  ConversationApi,
} from './Kamernet';
import { KamernetError } from './KamernetErrors';
import { OtherApi } from './Other';

const { publicRuntimeConfig } = getConfig();

const isServer = typeof global.window === 'undefined';

export class ApiClient extends ApiClientBase {
  alert: AlertApi;

  auth: AuthApi;

  favorite: FavoriteApi;

  listing: ListingApi;

  payment: PaymentApi;

  user: UserApi;

  search: SearchApi;

  tenant: TenantApi;

  affiliates: AffiliatesApi;

  gis: GisApi;

  lcf: LCFApi;

  conversation: ConversationApi;

  debug: DebugApi;

  other: OtherApi;

  constructor() {
    super({
      kamernetWebOrigin: publicRuntimeConfig.ORIGIN,
      kamernetApiOrigin: isServer
        ? publicRuntimeConfig.KAMERNET_API_ORIGIN
        : '/services/api',
    });

    const configuration = new Configuration({
      basePath: this.kamernetApiOrigin,
    });

    // @todo revisit response and error handling
    const normalizationMiddleware: Middleware = {
      pre: async context => {
        logger.info({
          message: `ApiClient: ${context.init.method} ${context.url} : requesting`,
          method: context.init.method,
          url: context.url,
        });

        const extraHeaders = {
          ...(isServer ? this.headers : undefined),
        };

        const authorizationHeader = this.authorizationHeaderGetter?.();
        if (authorizationHeader) {
          extraHeaders.Authorization = authorizationHeader;
        }

        return {
          url: context.url,
          init: {
            ...context.init,
            headers: {
              ...context.init.headers,
              ...extraHeaders,
            },
          },
        };
      },
      post: async context => {
        logger.info({
          message: `ApiClient: ${context.init.method} ${context.url} : responded with status ${context.response.status}`,
          method: context.init.method,
          url: context.url,
          status: context.response.status,
        });

        await this.handleResponse(
          Promise.resolve(context.response.clone()),
          KamernetError,
        );

        return context.response;
      },
      onError: async context => {
        logger.error(
          `ApiClient: ${context.init.method} ${context.url} : ${context.error}`,
        );

        if (context.response) {
          const { response } = await this.handleResponse(
            Promise.resolve(context.response.clone()),
            KamernetError,
          );

          return response;
        }

        return undefined;
      },
    };

    this.alert = new AlertApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.auth = new AuthApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.favorite = new FavoriteApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.gis = new GisApi(configuration).withMiddleware(
      normalizationMiddleware,
    );
    this.listing = new ListingApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.search = new SearchApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.tenant = new TenantApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.user = new UserApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.payment = new PaymentApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.affiliates = new AffiliatesApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.lcf = new LCFApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.conversation = new ConversationApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.debug = new DebugApi(configuration).withMiddleware(
      normalizationMiddleware,
    );

    this.other = new OtherApi(this);
  }
}
