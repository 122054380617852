import { parseRouteParamToInt } from '@kamernet/utilities/URL';

import { defineRoute } from '../defineRoute';

export const DEFAULT_TENANT_ID = 0;

export type TenantPublicProfileRouteParams = {
  tenantID: number;
};

export type TenantPublicProfileRouteParamsString = {
  tenantID: string;
};

export const TenantPublicProfile = defineRoute<
  TenantPublicProfileRouteParams,
  TenantPublicProfileRouteParamsString
>({
  pathname: '/offer-a-home/tenant/[tenantID]/mymessages',
  nlPathname: '/verhuren/huurder/[tenantID]/mymessages',
  routeParamsStringifier: routeParams => {
    return {
      tenantID: routeParams.tenantID.toString(),
    };
  },
  routeParamsParser: routeParamsString => {
    return {
      tenantID:
        parseRouteParamToInt(routeParamsString.tenantID) || DEFAULT_TENANT_ID,
    };
  },
});
