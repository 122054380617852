import {
  createTranslate,
  createNumberFormat,
  createDateTimeFormat,
  Translations,
  TranslateFunc as BaseTranslateFunc,
} from '@ha/intl';
import UntranslatableKeys from '@kamernet/locales/untranslatable-keys.json';
import { Locale, IntlObject, TranslateFunc } from './types';

export function createIntl({
  locale,
  messages,
}: {
  locale: Locale;
  messages: Translations;
}): IntlObject {
  const { T: baseT, ...translateRest } = createTranslate(messages);

  const errorMessage: TranslateFunc['errorMessage'] = (
    error,
    defaultMessage,
  ) => {
    // const message = extractErrorMessage(error, defaultMessage);
    // eslint-disable-next-line custom/no-restricted-error-message
    const message = (error as Error).message || defaultMessage;
    return message ? baseT(message) : '';
  };

  const implementationT: BaseTranslateFunc = function T(key, ...args) {
    let result = baseT(key, ...args);

    if (result.includes('{') && args.length > 0) {
      result = result.replaceAll(/\{(.+?)\}/g, (_, k) =>
        typeof args[0] === 'object' ? args[0][k] : args[k],
      );
    }

    return result;
  };

  const T: TranslateFunc = Object.assign(
    process.env.NODE_ENV === 'development'
      ? (((key, ...args) => {
          if (UntranslatableKeys[key as keyof typeof UntranslatableKeys]) {
            return key;
          }

          if (!(key in messages) || !messages[key]) {
            return `__${key}__`;
          }

          return implementationT(key, ...args);
        }) as BaseTranslateFunc)
      : implementationT,
    {
      errorMessage,
    },
  );

  const intl: IntlObject = {
    locale,
    T,
    messages,
    ...translateRest,
    ...createNumberFormat(locale),
    ...createDateTimeFormat(locale),
  };

  return intl;
}
