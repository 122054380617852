import type { ActiveTenantsCountResponse } from '../models/index';
import * as runtime from '../runtime';

export interface GetActiveTenantsCountRequest {
  cityName?: string;
}

/**
 *
 */
export class TenantApi extends runtime.BaseAPI {
  /**
   */
  async getActiveTenantsCountRaw(
    requestParameters: GetActiveTenantsCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ActiveTenantsCountResponse>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.cityName != null) {
      queryParameters.cityName = requestParameters.cityName;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/tenant/activetenantscount`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Active tenants counts for a given city name. if no city name is provided it will return the active tenants count for the Netherlands
   */
  async getActiveTenantsCount(
    requestParameters: GetActiveTenantsCountRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ActiveTenantsCountResponse> {
    const response = await this.getActiveTenantsCountRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }
}
