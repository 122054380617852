import { AppContext } from 'next/app';
import { IAuthState } from './types';
import { OAuthTokenStore } from './OAuthTokenStore';

export function initAuthBrowserApp(appContext: AppContext): IAuthState {
  const authState: IAuthState = {
    userProfile: null,
    anonymousId: null,
    accessToken: null,
  };

  /**
   * interpret data passed form server
   */
  const serverAuthState: IAuthState | undefined =
    appContext.router.components[appContext.router.route]?.props?.authState;

  if (serverAuthState) {
    Object.assign(authState, serverAuthState);
  }

  OAuthTokenStore.setAccessToken(authState.accessToken);

  return authState;
}
