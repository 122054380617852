/**
 *
 */
export enum ListingDeactivationReason {
  FoundViaKamernet = 1,
  FoundViaNetwork = 2,
  FoundViaOtherWebsite = 3,
  EnoughReplies = 15,
  UndesirableReplies = 16,
  NotSearchingAnymore = 17,
}
