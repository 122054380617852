import { oauthRefreshAccessToken } from '@kamernet/core/Auth/apis/oauthRefreshAccessToken/oauthRefreshAccessToken.client';
import { oauthSigninGoogleOneTap } from '@kamernet/core/Auth/apis/oauthSigninGoogleOneTap/oauthSigninGoogleOneTap.client';

import { activeTenantCount } from '@kamernet/modules/Commons/apis/activeTenantCount/activeTenantCount.client';
import { averageRentoutDay } from '@kamernet/modules/Commons/apis/averageRentoutDay/averageRentoutDay.client';
import { locationSuggestions } from '@kamernet/modules/Commons/apis/locationSuggestions/locationSuggestions.client';

// @generator-placeholder:api-client-import <= used by kamernet-api code generator to inject client function import

import { APIClientExtension } from '../APIClientExtension';

export class OtherApi extends APIClientExtension {
  oauthRefreshAccessToken = oauthRefreshAccessToken;

  oauthSigninGoogleOneTap = oauthSigninGoogleOneTap;

  averageRentoutDay = averageRentoutDay;

  activeTenantCount = activeTenantCount;

  locationSuggestions = locationSuggestions;

  // @generator-placeholder:api-client-method <= used by kamernet-api code generator to inject client function as method
}
