/**
 *
 */
export enum ListingStatusTypeID {
  Concept = -1,
  Created = 1,
  Rejected = 2,
  Approved = 3,
  Active = 4,
  Inactive = 5,
  Deleted = 6,
  PossibleAbuse = 7,
  Blocked = 8,
  Fraud = 9,
  AutomaticallyActivated = 10,
  Pending = 11,
}
