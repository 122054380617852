/**
 *
 */
export enum MarkConversationAction {
  Unread = 1,
  Read = 2,
  Favorite = 3,
  Unfavorite = 4,
  Trash = 5,
  Delete = 6,
  Restore = 7,
}
