import { createLogger, LevelWithSilent } from '@hbf/log';

let level: LevelWithSilent = 'info';

// This is a workaround for Storybook, since process is not available
// on a static built website.
if (globalThis?.process?.env?.NODE_ENV === 'development') {
  level = 'debug';
}

if (globalThis?.process?.env?.LOG_LEVEL) {
  level = globalThis?.process?.env?.LOG_LEVEL as LevelWithSilent;
}

export const logger = createLogger({
  level,
  name: 'kamernet',
  ...(process.env.NEXT_RUNTIME === 'edge'
    ? {
        browser: {
          write(obj) {
            try {
              console.log(JSON.stringify(obj));
            } catch (err) {
              if (err instanceof Error) {
                console.log(JSON.stringify(err, ['name', 'message', 'stack']));
              }

              console.log(JSON.stringify({ message: 'Unknown error type' }));
            }
          },
        },
      }
    : {}),
});
