/**
 *
 */
export enum LCFStep {
  ListingType = 1,
  OwnerType = 2,
  StudentHouse = 3,
  Address = 4,
  Availability = 5,
  SurfaceFurnishing = 6,
  RentalPrice = 7,
  Media = 8,
  Details = 10,
  PreferredTenant = 11,
  CurrentResidents = 12,
  TitleDescription = 13,
}
