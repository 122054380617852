import type {
  GoogleOneTapCredential,
  LoginPayload,
  LoginResponse,
} from '../models/index';
import * as runtime from '../runtime';

export interface LoginRequest {
  loginPayload: LoginPayload;
}

export interface SigninGoogleOneTapLegacyRequest {
  googleOneTapCredential: GoogleOneTapCredential;
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
  /**
   */
  async loginRaw(
    requestParameters: LoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LoginResponse>> {
    if (requestParameters.loginPayload == null) {
      throw new runtime.RequiredError(
        'loginPayload',
        'Required parameter "loginPayload" was null or undefined when calling login().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/customer/login`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.loginPayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Login user.
   */
  async login(
    requestParameters: LoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LoginResponse> {
    const response = await this.loginRaw(requestParameters, initOverrides);
    return response.value();
  }

  /**
   */
  async signinGoogleOneTapLegacyRaw(
    requestParameters: SigninGoogleOneTapLegacyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<LoginResponse>> {
    if (requestParameters.googleOneTapCredential == null) {
      throw new runtime.RequiredError(
        'googleOneTapCredential',
        'Required parameter "googleOneTapCredential" was null or undefined when calling signinGoogleOneTapLegacy().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/customer/googleonetap`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.googleOneTapCredential,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Signin using google one tap credentials
   */
  async signinGoogleOneTapLegacy(
    requestParameters: SigninGoogleOneTapLegacyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<LoginResponse> {
    const response = await this.signinGoogleOneTapLegacyRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }
}
