import { ApiClientBase } from '@kamernet/core/ApiClient/ApiClientBase';

import { ACTIVE_TENANT_COUNT_API_ROUTE } from '.';

export function activeTenantCount(
  this: ApiClientBase,
  cityName: string,
  init?: RequestInit | undefined,
) {
  return this.handleResponse<number>(
    fetch(
      `${this.kamernetWebOrigin}${ACTIVE_TENANT_COUNT_API_ROUTE}?cityName=${cityName}`,
      {
        method: 'GET',
        headers: { ...this.headers },
        ...init,
      },
    ),
  );
}
