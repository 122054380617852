import { AppContext } from 'next/app';

import { AppCookieName } from '@kamernet/core/Cookie/types';
import { reportError } from '@kamernet/core/Errors';
import { CustomTags, ErrorModules } from '@kamernet/core/Errors/types';

import { getFlagsmithServerClient } from './initializeFlagsmithServerClient';
import {
  FeatureFlags,
  DEFAULT_FEATURE_FLAGS,
  DEFAULT_USER_FEATURE_FLAGS,
  DEFAULT_SESSION_FEATURE_FLAGS,
  SessionFeatureFlags,
  UserFeatureFlags,
} from './types';

async function retrieveSpecificFeatureFlags<TKeys extends keyof FeatureFlags>(
  appContext: AppContext,
  featureFlagNamesToRetrieve: TKeys[],
  identifier: string,
  traits?: {
    [key: string]: string;
  },
): Promise<Pick<FeatureFlags, TKeys>> {
  let result: Pick<FeatureFlags, TKeys>;

  try {
    const flagsmith = getFlagsmithServerClient();
    if (
      !featureFlagNamesToRetrieve ||
      featureFlagNamesToRetrieve.length === 0
    ) {
      return {} as Pick<FeatureFlags, TKeys>;
    }

    const flagsmithFeatureFlags = (
      await flagsmith.getIdentityFlags(identifier, traits)
    ).flags;

    result = Object.keys(DEFAULT_FEATURE_FLAGS).reduce((p, featureFlagName) => {
      if (!featureFlagNamesToRetrieve.includes(featureFlagName as TKeys)) {
        return { ...p };
      }

      const flagsmithFeatureFlag = flagsmithFeatureFlags[featureFlagName];

      if (!flagsmithFeatureFlag || !flagsmithFeatureFlag.enabled) {
        return {
          ...p,
          [featureFlagName]: DEFAULT_FEATURE_FLAGS[featureFlagName as TKeys],
        };
      }

      if (flagsmithFeatureFlag.value != null) {
        return { ...p, [featureFlagName]: flagsmithFeatureFlag.value };
      }

      return { ...p, [featureFlagName]: true };
    }, {} as Pick<FeatureFlags, TKeys>);
  } catch (error) {
    reportError(error as Error, {
      extra: {
        context: 'Error in initialising feature flags',
        metaData: { error, identifier },
      },
      tags: {
        [CustomTags.KN_Module]: ErrorModules.InitFeatureFlags,
      },
    });

    result = DEFAULT_FEATURE_FLAGS;
  }

  const req = appContext.ctx.req!;
  Object.assign(req, {
    featureFlags: {
      ...req.featureFlags,
      ...result,
    },
  });

  return result;
}

export function initUserFeatureFlagsServerApp(
  appContext: AppContext,
): Promise<UserFeatureFlags> {
  const req = appContext.ctx.req!;
  const { userProfile } = req.authState || {};
  const anonymousId = req.cookies[AppCookieName.ANONYMOUS_ID];
  const userId = userProfile?.userid;
  const traits: Record<string, string> = {};

  if (userId) {
    traits.userId = userId;
  }

  if (anonymousId) {
    traits.anonymousId = anonymousId;
  }

  return retrieveSpecificFeatureFlags(
    appContext,
    Object.keys(DEFAULT_USER_FEATURE_FLAGS) as Array<keyof UserFeatureFlags>,
    userId || anonymousId || '0',
    traits,
  );
}

export function initSessionFeatureFlagsServerApp(
  appContext: AppContext,
): Promise<SessionFeatureFlags> {
  const req = appContext.ctx.req!;
  const anonymousId = req.cookies[AppCookieName.ANONYMOUS_ID];

  const traits: Record<string, string> = {};

  if (anonymousId) {
    traits.anonymousId = anonymousId;
  }

  return retrieveSpecificFeatureFlags(
    appContext,
    Object.keys(DEFAULT_SESSION_FEATURE_FLAGS) as Array<
      keyof SessionFeatureFlags
    >,
    anonymousId || '0',
    traits,
  );
}
