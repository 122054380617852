import React from 'react';

import { useRouter } from 'next/router';

import { ListingRequest } from '@kamernet/core/ApiClient/Kamernet';
import { AppServicesContext } from '@kamernet/core/AppServices';
import { AuthContext } from '@kamernet/core/Auth/AuthContext';
import { useLocalStorage } from '@kamernet/core/BrowserStorage';
import { reportError } from '@kamernet/core/Errors';
import { CustomTags, ErrorModules } from '@kamernet/core/Errors/types';

export const FlushAnonymousPSA = () => {
  const router = useRouter();
  const { isAuthenticated, userProfile } = AuthContext.useContext();
  const { apiClient } = AppServicesContext.useContext();

  const [anonymousPSA, setAnonymousPSA, isAnonymousPSAReady] =
    useLocalStorage('kn-anonymous-psa');

  React.useEffect(() => {
    if (
      isAnonymousPSAReady &&
      isAuthenticated &&
      typeof router.query.signedin === 'string' &&
      router.query.signedin.toLowerCase() === 'true'
    ) {
      setAnonymousPSA(undefined);
      if (
        !userProfile?.haspremiumaccount &&
        anonymousPSA?.listingRequests &&
        anonymousPSA.listingRequests.length > 0
      ) {
        apiClient.alert
          .processAnonymousPSA({
            processAnonymousPSARequest: {
              psaList: (anonymousPSA?.listingRequests ||
                []) as ListingRequest[],
            },
          })
          .catch(error => {
            reportError(error as Error, {
              extra: {
                context: 'Error calling processAnonymousPSA api',
              },
              tags: {
                [CustomTags.KN_Module]: ErrorModules.AnonymousPSA,
              },
            });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnonymousPSAReady]);

  return null;
};
