import React from 'react';

import { reportError } from '@kamernet/core/Errors';

import { StorageItems, StorageKey } from './types';

function parse<TKey extends StorageKey>(
  serialized: string | null | undefined,
  key: TKey,
) {
  try {
    return serialized
      ? (JSON.parse(serialized) as StorageItems[TKey])
      : undefined;
  } catch (ex) {
    reportError(ex as Error, {
      extra: {
        context: `Error parsing value. Key: ${key}`,
        metaData: { ex },
      },
    });
  }

  return undefined;
}

export function useStorage<TKey extends StorageKey>(
  key: TKey,
  storage: Storage,
): [
  StorageItems[TKey] | undefined | null,
  (newValue: StorageItems[TKey]) => void,
  boolean,
] {
  const rawValueRef = React.useRef<string | null>(null);
  const [value, setValue] = React.useState<StorageItems[TKey] | undefined>();
  const [isReady, setIsReady] = React.useState<boolean>(false);

  React.useEffect(() => {
    rawValueRef.current = storage.getItem(key);
    setValue(parse(rawValueRef.current, key));
    setIsReady(true);

    const storageEventHandler = (event: StorageEvent): void => {
      if (
        event.storageArea === storage &&
        event.key === key &&
        event.newValue !== rawValueRef.current
      ) {
        rawValueRef.current = event.newValue;
        setValue(parse(event.newValue, key));
      }
    };

    window.addEventListener('storage', storageEventHandler);

    return () => window.removeEventListener('storage', storageEventHandler);
  }, [key, storage]);

  const set = React.useCallback(
    (newValue: StorageItems[TKey] | null | undefined): void => {
      try {
        const oldValue = rawValueRef.current;
        if (newValue === undefined || newValue === null) {
          rawValueRef.current = null;
          storage.removeItem(key);
        } else {
          rawValueRef.current = JSON.stringify(newValue);
          storage.setItem(key, rawValueRef.current);
        }
        setValue(newValue);
        window.dispatchEvent(
          new StorageEvent('storage', {
            storageArea: storage,
            key,
            url: window.location.href,
            newValue: rawValueRef.current,
            oldValue,
          }),
        );
      } catch (ex) {
        reportError(ex as Error, {
          extra: {
            context: `Error setting value to storage. Key: ${key}`,
            metaData: { ex },
          },
        });
      }
    },
    [key, storage],
  );

  return [value, set, isReady];
}
