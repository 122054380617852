import React from 'react';
import { ROUTES } from '@kamernet/core/Routing';
import { useIntl } from '@kamernet/core/Intl';
import { reset } from '@hbf/analytics';

import { IAuth, IAuthState } from './types';
import { AuthContext } from './AuthContext';

export const AuthProvider = ({
  authState,
  children,
}: {
  authState: IAuthState;
  children: React.ReactNode;
}) => {
  const { locale } = useIntl();

  const signout: IAuth['signout'] = React.useCallback(() => {
    reset();
    window.mixpanel?.reset();
    window.location.assign(ROUTES.Signout.url({}, locale));
  }, [locale]);

  const value: IAuth = React.useMemo(
    () => ({
      ...authState,
      isAuthenticated: !!authState.userProfile,
      signout,
    }),
    [authState, signout],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
