import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { ThemeOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

import { DesignTokens } from './theme.design';

export function pixelsToREM(x: number) {
  return `${x / 16}rem`;
}

// @todo fontFamily is needed as a global inside typographyConfig so that linting works. We should fix this in the future.
const typographyConfig: TypographyOptions = {
  fontFamily: DesignTokens.bodyFontFamily,
  h1: {
    fontFamily: DesignTokens.headerFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(44),
    lineHeight: pixelsToREM(56),
  },
  h2: {
    fontFamily: DesignTokens.headerFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(36),
    lineHeight: pixelsToREM(44),
  },
  h3: {
    fontFamily: DesignTokens.headerFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(28),
    lineHeight: pixelsToREM(36),
  },
  h4: {
    fontFamily: DesignTokens.headerFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(24),
    lineHeight: pixelsToREM(32),
  },
  h5: {
    fontFamily: DesignTokens.headerFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(20),
    lineHeight: pixelsToREM(28),
  },
  h6: {
    fontFamily: DesignTokens.headerFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(16),
    lineHeight: pixelsToREM(24),
  },
  subtitle1: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: pixelsToREM(18),
    lineHeight: pixelsToREM(28),
  },
  subtitle2: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: pixelsToREM(16),
    lineHeight: pixelsToREM(24),
  },
  subtitle3: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: pixelsToREM(14),
    lineHeight: pixelsToREM(20),
  },
  body1: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: pixelsToREM(18),
    lineHeight: pixelsToREM(28),
  },
  body2: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: pixelsToREM(16),
    lineHeight: pixelsToREM(24),
  },
  body3: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: pixelsToREM(14),
    lineHeight: pixelsToREM(20),
  },
  body4: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: pixelsToREM(12),
    lineHeight: pixelsToREM(16),
  },
  body1Bold: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(18),
    lineHeight: pixelsToREM(28),
  },
  body2Bold: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(16),
    lineHeight: pixelsToREM(24),
  },
  body3Bold: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(14),
    lineHeight: pixelsToREM(20),
  },
  body4Bold: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(12),
    lineHeight: pixelsToREM(16),
  },
  caption: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: pixelsToREM(11),
    lineHeight: pixelsToREM(16),
  },
  overline: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: pixelsToREM(11),
    lineHeight: pixelsToREM(16),
  },
  buttonLarge: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: pixelsToREM(18),
    lineHeight: pixelsToREM(24),
    textTransform: 'none',
  },
  buttonMedium: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: pixelsToREM(16),
    lineHeight: pixelsToREM(20),
    textTransform: 'none',
  },
  buttonSmall: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(14),
    lineHeight: pixelsToREM(16),
    textTransform: 'none',
  },
  buttonXSmall: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(12),
    lineHeight: pixelsToREM(16),
    textTransform: 'none',
  },
  linkLarge: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: pixelsToREM(18),
    lineHeight: pixelsToREM(24),
  },
  linkMedium: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: pixelsToREM(16),
    lineHeight: pixelsToREM(20),
  },
  linkSmall: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(14),
    lineHeight: pixelsToREM(16),
  },
  linkXSmall: {
    fontFamily: DesignTokens.bodyFontFamily,
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: pixelsToREM(12),
    lineHeight: pixelsToREM(16),
  },
};

export const MuiThemeConfig: ThemeOptions = {
  breakpoints: {
    values: {
      xs: DesignTokens.breakpoint.xs,
      sm: DesignTokens.breakpoint.sm,
      md: DesignTokens.breakpoint.md,
      lg: DesignTokens.breakpoint.lg,
      xl: DesignTokens.breakpoint.xl,
    },
  },
  palette: {
    text: {
      primary: DesignTokens.color.text.primary,
      secondary: DesignTokens.color.text.secondary,
    },
    primary: DesignTokens.color.primary,
    secondary: DesignTokens.color.secondary,
    tertiary: DesignTokens.color.tertiary,
    success: DesignTokens.color.success,
    danger: DesignTokens.color.danger,
    warning: DesignTokens.color.warning,
    mono: DesignTokens.color.mono,
    neutral: DesignTokens.color.neutral,
    info: DesignTokens.color.neutral,
  },
  typography: typographyConfig,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle3: 'h6',
          body3: 'p',
          body4: 'p',
          body1Bold: 'p',
          body2Bold: 'p',
          body3Bold: 'p',
          body4Bold: 'p',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          color: DesignTokens.color.secondary.main,
          padding: 6,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          color: DesignTokens.color.secondary.main,
          padding: 6,
        },
      },
    },

    MuiMenuItem: {
      defaultProps: {
        color: 'secondary',
      },
      styleOverrides: {
        root: {
          color: DesignTokens.color.secondary.main,
        },
      },
    },
    MuiSelect: {
      defaultProps: { IconComponent: KeyboardArrowDownOutlinedIcon },
      styleOverrides: {
        icon: {
          color: DesignTokens.color.secondary.main,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          display: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: () => ({
          '& fieldset, & .MuiOutlinedInput-root': {
            borderColor: DesignTokens.color.mono.main,
            borderRadius: pixelsToREM(8),
          },
          '&:hover:not(.Mui-focused):not(.Mui-disabled)': {
            '& fieldset, & fieldset.MuiOutlinedInput-notchedOutline': {
              borderColor: DesignTokens.color.mono.main,
            },
          },
        }),
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [`@media (min-width: ${1360}px)`]: {
            maxWidth: '1200px',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      variants: [
        {
          props: { size: 'large' },
          style: {
            ...typographyConfig.buttonMedium,
            textTransform: 'none', // Having textTransform: 'none' inside typographyConfig object gives error
            padding: `${pixelsToREM(8)} ${pixelsToREM(16)}`,
            height: pixelsToREM(48),
            '& .MuiButton-startIcon': {
              '& > *:first-of-type': {
                width: pixelsToREM(20),
                height: pixelsToREM(20),
              },
            },
            '& .MuiButton-endIcon': {
              '& > *:last-of-type': {
                width: pixelsToREM(20),
                height: pixelsToREM(20),
              },
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            ...typographyConfig.buttonMedium,
            textTransform: 'none', // Having textTransform: 'none' inside typographyConfig object gives error
            padding: `${pixelsToREM(8)} ${pixelsToREM(12)}`,
            height: pixelsToREM(40),
            '& .MuiButton-startIcon': {
              '& > *:first-of-type': {
                width: pixelsToREM(20),
                height: pixelsToREM(20),
              },
            },
            '& .MuiButton-endIcon': {
              '& > *:last-of-type': {
                width: pixelsToREM(20),
                height: pixelsToREM(20),
              },
            },
          },
        },
        {
          props: { size: 'small' },
          style: {
            ...typographyConfig.buttonSmall,
            textTransform: 'none', // Having textTransform: 'none' inside typographyConfig object gives error
            padding: pixelsToREM(8),
            height: pixelsToREM(32),
            '& .MuiButton-startIcon': {
              '& > *:first-of-type': {
                width: pixelsToREM(16),
                height: pixelsToREM(16),
              },
            },
            '& .MuiButton-endIcon': {
              '& > *:last-of-type': {
                width: pixelsToREM(16),
                height: pixelsToREM(16),
              },
            },
          },
        },
        {
          props: { color: 'tertiary' },
          style: {
            color: DesignTokens.color.neutral.light,
            backgroundColor: DesignTokens.color.tertiary.main,
            '&:hover': {
              color: DesignTokens.color.neutral.light,
              backgroundColor: DesignTokens.color.tertiary.light,
            },
            '&:focus': {
              color: DesignTokens.color.neutral.light,
              backgroundColor: DesignTokens.color.tertiary.light,
            },
            '&:active': {
              color: DesignTokens.color.neutral.light,
              backgroundColor: DesignTokens.color.tertiary.dark,
            },
            '&:disabled': {
              backgroundColor: DesignTokens.color.mono.main,
              color: DesignTokens.color.neutral.gray,
            },
          },
        },
        {
          props: { variant: 'link' },
          style: {
            textDecoration: 'underline !important',
            background: 'none !important',
            color: DesignTokens.color.secondary.main,
            '&:hover': {
              color: DesignTokens.color.secondary.light,
            },
            '&:focus': {
              color: DesignTokens.color.secondary.light,
            },
            '&:active': {
              color: DesignTokens.color.secondary.dark,
            },
            '&:disabled': {
              color: DesignTokens.color.neutral.gray,
              textDecoration: 'none',
            },
          },
        },
        {
          props: { variant: 'destructive' },
          style: {
            backgroundColor: DesignTokens.color.danger.main,
            color: DesignTokens.color.neutral.light,

            '&:hover': {
              color: DesignTokens.color.neutral.light,
              backgroundColor: DesignTokens.color.danger.light,
            },
            '&:focus': {
              color: DesignTokens.color.neutral.light,
              backgroundColor: DesignTokens.color.danger.light,
            },
            '&:active': {
              color: DesignTokens.color.neutral.light,
              backgroundColor: DesignTokens.color.danger.dark,
            },
            '&:disabled': {
              backgroundColor: DesignTokens.color.mono.main,
              color: DesignTokens.color.neutral.gray,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: pixelsToREM(8),
        },
        containedPrimary: {
          color: DesignTokens.color.neutral.light,
          backgroundColor: DesignTokens.color.primary.main,
          '&:hover': {
            color: DesignTokens.color.neutral.light,
            backgroundColor: DesignTokens.color.primary.light,
          },
          '&:focus': {
            color: DesignTokens.color.neutral.light,
            backgroundColor: DesignTokens.color.primary.main,
          },
          '&:active': {
            color: DesignTokens.color.neutral.light,
            backgroundColor: DesignTokens.color.primary.dark,
          },
          '&:disabled': {
            backgroundColor: DesignTokens.color.mono.main,
            color: DesignTokens.color.neutral.gray,
          },
        },
        containedSecondary: {
          color: DesignTokens.color.neutral.light,
          backgroundColor: DesignTokens.color.secondary.main,
          '&:hover': {
            color: DesignTokens.color.neutral.light,
            backgroundColor: DesignTokens.color.secondary.light,
          },
          '&:focus': {
            color: DesignTokens.color.neutral.light,
            backgroundColor: DesignTokens.color.secondary.main,
          },
          '&:active': {
            color: DesignTokens.color.neutral.light,
            backgroundColor: DesignTokens.color.secondary.dark,
          },
          '&:disabled': {
            backgroundColor: DesignTokens.color.mono.main,
            color: DesignTokens.color.neutral.gray,
          },
        },
        outlined: {
          background: 'none !important',
          borderColor: DesignTokens.color.secondary.main,
          color: DesignTokens.color.secondary.dark,

          '&:hover': {
            borderColor: DesignTokens.color.secondary.light,
            backgroundColor: DesignTokens.color.mono.main,
            color: DesignTokens.color.secondary.light,
          },
          '&:focus': {
            backgroundColor: DesignTokens.color.mono.main,
            color: DesignTokens.color.secondary.light,
          },
          '&:active': {
            borderColor: DesignTokens.color.secondary.dark,
            backgroundColor: DesignTokens.color.mono.dark,
            color: DesignTokens.color.secondary.dark,
          },
          '&:disabled': {
            color: DesignTokens.color.neutral.gray,
          },
        },
        text: {
          background: 'none !important',
          color: DesignTokens.color.secondary.dark,
          '&:hover': {
            backgroundColor: DesignTokens.color.mono.main,
            color: DesignTokens.color.secondary.light,
          },
          '&:focus': {
            backgroundColor: DesignTokens.color.mono.main,
            color: DesignTokens.color.secondary.light,
          },
          '&:active': {
            backgroundColor: DesignTokens.color.mono.dark,
            color: DesignTokens.color.secondary.dark,
          },
          // @todo missing transparent background
          '&:disabled': {
            color: DesignTokens.color.neutral.gray,
          },
        },
        startIcon: () => ({
          '& > *:first-of-type': {
            width: pixelsToREM(20),
            height: pixelsToREM(20),
          },
        }),
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          color: DesignTokens.color.secondary.dark,
          padding: 0,
        },
        grouped: {
          width: 'inherit',
          textTransform: 'none',
          color: DesignTokens.color.secondary.dark,
          paddingInline: `calc(var(--mui-spacing) * 2)`,
          paddingBlock: `calc(var(--mui-spacing) * 0.7)`,
          '&:not(:last-of-type)': {
            color: DesignTokens.color.secondary.dark,
            backgroundColor: DesignTokens.color.common.white,
            border: `1px solid ${DesignTokens.color.mono.dark}`,
            borderRadius: `calc(var(--mui-spacing)) 0 0 calc(var(--mui-spacing))`,
            '&:hover': {
              backgroundColor: DesignTokens.color.mono.dark,
            },
          },
          '&:not(:first-of-type)': {
            color: DesignTokens.color.secondary.dark,
            backgroundColor: DesignTokens.color.common.white,
            border: `1px solid ${DesignTokens.color.mono.dark}`,
            borderRadius: `0 calc(var(--mui-spacing)) calc(var(--mui-spacing)) 0 `,
            borderLeft: `1px solid ${DesignTokens.color.mono.dark}`,
            '&:hover': {
              backgroundColor: DesignTokens.color.mono.dark,
            },
          },
          '&.Mui-selected': {
            color: DesignTokens.color.secondary.dark,
            border: `1px solid ${DesignTokens.color.mono.dark}`,
            backgroundColor: DesignTokens.color.mono.main,
            '&:hover': {
              border: `1px solid ${DesignTokens.color.mono.dark}`,
              backgroundColor: DesignTokens.color.mono.main,
            },
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        disableInteractive: true,
        enterDelay: 700,
      },
      styleOverrides: {
        tooltip: () => ({
          backgroundColor: DesignTokens.color.secondary.main,
          color: DesignTokens.color.common.white,
          fontFamily: DesignTokens.bodyFontFamily,
          fontWeight: 400,
          fontStyle: 'normal',
          fontSize: pixelsToREM(16),
          lineHeight: pixelsToREM(24),
          paddingInline: 16,
          paddingBlock: 12,
          borderRadius: 8,
          display: 'grid',
          gap: 8,
        }),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontFamily: DesignTokens.bodyFontFamily,
          fontWeight: 400,
          fontStyle: 'normal',
          fontSize: pixelsToREM(16),
          lineHeight: pixelsToREM(24),
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: '0 0 10px 0 rgb(0 22 63 / 15%)',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 4,
        },
      },
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'small' },
          style: {
            fontSize: pixelsToREM(20),
          },
        },
        {
          props: { fontSize: 'medium' },
          style: {
            fontSize: pixelsToREM(24),
          },
        },
        {
          props: { fontSize: 'large' },
          style: {
            fontSize: pixelsToREM(28),
          },
        },
      ],
    },
    MuiPaginationItem: {
      styleOverrides: {
        page: {
          '&.Mui-selected': {
            backgroundColor: DesignTokens.color.secondary.dark,
            color: DesignTokens.color.common.white,
            '&:hover': {
              backgroundColor: DesignTokens.color.secondary.dark,
              color: DesignTokens.color.common.white,
              opacity: 0.9,
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorBottom: {
          borderTopLeftRadius: pixelsToREM(16),
          borderTopRightRadius: pixelsToREM(16),
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: pixelsToREM(8),
        },
        standardSuccess: {
          backgroundColor: DesignTokens.color.success.accent,
          color: 'white',
        },
        standardError: {
          backgroundColor: DesignTokens.color.danger.accent,
          color: 'white',
        },
        standardWarning: {
          backgroundColor: DesignTokens.color.warning.accent,
          color: 'white',
        },
        standardInfo: {
          backgroundColor: DesignTokens.color.tertiary.accent,
          color: 'black',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: DesignTokens.color.secondary.main,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: DesignTokens.borderRadius.sm,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: DesignTokens.borderRadius.sm,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
};
