/**
 *
 */
export enum PaymentMethodId {
  Ideal = 8,
  AfterPay = 9,
  DirectDebit = 10,
  Visa = 11,
  MasterCard = 12,
  Maestro = 13,
  CreditCard = 14,
  BuckarooIdeal = 15,
  BuckarooDirectDebit = 16,
  BuckarooMastercard = 17,
  BuckarooVisa = 18,
  BuckarooMaestro = 19,
  Paypal = 20,
  EMandate = 21,
  SofortBanking = 22,
  Bancontact = 23,
  BuckarooAfterPay = 24,
  BuckarooSmartCheck = 25,
  StripeIdeal = 26,
  StripeCard = 27,
  StripeSofort = 28,
  StripeBancontact = 29,
  StripeDirectDebit = 30,
  StripePaypal = 31,
  Other = -9999,
}
