import { BaseTransportOptions, Options } from '@sentry/types';
import getConfig from 'next/config';

import { sampleCommonErrors } from '@kamernet/utilities/Sentry/filterErrors';

const { publicRuntimeConfig } = getConfig() || {};

export const SENTRY_BASE_CONFIG: Options<BaseTransportOptions> = {
  debug: false,
  release: process.env.BUILD_ID,
  dsn: publicRuntimeConfig?.SENTRY_DSN || '',
  sampleRate: publicRuntimeConfig?.SENTRY_SAMPLE_RATE || 0,
  tracesSampleRate: publicRuntimeConfig?.SENTRY_TRACES_SAMPLE_RATE || 0,
  environment: publicRuntimeConfig?.DEPLOYMENT_ID || '',
  normalizeDepth: publicRuntimeConfig?.SENTRY_NORMALIZE_DEPTH || 0,
  beforeSend: sampleCommonErrors(
    publicRuntimeConfig?.SENTRY_SAMPLE_COMMON_ERRORS_RATE || 0.01,
  ),
};
