import { Enum, EnumObject, EnumValues } from '@ha/core/utilities';

import { parseRouteParamToInt } from './parseRouteParamToInt';
import { parseRouteParamToString } from './parseRouteParamToString';

export function parseRouteParamToEnum<T extends EnumObject>(
  value: string | null | undefined,
  enumType: T,
): EnumValues<T> | null {
  const enumValues = Enum.values(enumType);
  const firstEnumValue = enumValues[0];

  if (firstEnumValue == null) {
    throw new Error('Enum has to have at least 1 value');
  }

  const parsedValue = (
    typeof firstEnumValue === 'string'
      ? parseRouteParamToString(value)
      : parseRouteParamToInt(value)
  ) as EnumValues<T>;

  return parsedValue != null && Enum.values(enumType).includes(parsedValue)
    ? parsedValue
    : null;
}
