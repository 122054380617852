export function parseRouteParamToInt(
  value: string | null | undefined,
): number | null {
  try {
    if (typeof value === 'string') {
      const parsed = parseInt(value, 10);
      return Number.isNaN(parsed) ? null : parsed;
    }
  } catch {
    // ignore
  }

  return null;
}
