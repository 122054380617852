import type {
  CreateAlertRequest,
  CreateAlertResponse,
  ProcessAnonymousPSARequest,
  SaveAlertToSessionResponse,
} from '../models/index';
import * as runtime from '../runtime';

export interface CreateAlertOperationRequest {
  createAlertRequest: CreateAlertRequest;
}

export interface ProcessAnonymousPSAOperationRequest {
  processAnonymousPSARequest: ProcessAnonymousPSARequest;
}

export interface SaveAlertToSessionRequest {
  setSearchVisibility: boolean;
}

export interface UnsubscribeProactiveSearchAlertRequest {
  psaIdentifier: string;
}

/**
 *
 */
export class AlertApi extends runtime.BaseAPI {
  /**
   */
  async createAlertRaw(
    requestParameters: CreateAlertOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateAlertResponse>> {
    if (requestParameters.createAlertRequest == null) {
      throw new runtime.RequiredError(
        'createAlertRequest',
        'Required parameter "createAlertRequest" was null or undefined when calling createAlert().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/alert/createalert`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createAlertRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Save alert to database.
   */
  async createAlert(
    requestParameters: CreateAlertOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateAlertResponse> {
    const response = await this.createAlertRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async processAnonymousPSARaw(
    requestParameters: ProcessAnonymousPSAOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.processAnonymousPSARequest == null) {
      throw new runtime.RequiredError(
        'processAnonymousPSARequest',
        'Required parameter "processAnonymousPSARequest" was null or undefined when calling processAnonymousPSA().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/alert/processpsa`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.processAnonymousPSARequest,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Processes anonymous listing requests made by user when not logged in for PSA emails
   */
  async processAnonymousPSA(
    requestParameters: ProcessAnonymousPSAOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.processAnonymousPSARaw(requestParameters, initOverrides);
  }

  /**
   */
  async saveAlertToSessionRaw(
    requestParameters: SaveAlertToSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<SaveAlertToSessionResponse>> {
    if (requestParameters.setSearchVisibility == null) {
      throw new runtime.RequiredError(
        'setSearchVisibility',
        'Required parameter "setSearchVisibility" was null or undefined when calling saveAlertToSession().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.setSearchVisibility != null) {
      queryParameters.setSearchVisibility =
        requestParameters.setSearchVisibility;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/alert/savealerttosession`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Save alert to backend user session.
   */
  async saveAlertToSession(
    requestParameters: SaveAlertToSessionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<SaveAlertToSessionResponse> {
    const response = await this.saveAlertToSessionRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async unsubscribeProactiveSearchAlertRaw(
    requestParameters: UnsubscribeProactiveSearchAlertRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.psaIdentifier == null) {
      throw new runtime.RequiredError(
        'psaIdentifier',
        'Required parameter "psaIdentifier" was null or undefined when calling unsubscribeProactiveSearchAlert().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.psaIdentifier != null) {
      queryParameters.psaIdentifier = requestParameters.psaIdentifier;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/alert/unsubscribepsa`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Unsubscribes from proactive search alert for a given identifier
   */
  async unsubscribeProactiveSearchAlert(
    requestParameters: UnsubscribeProactiveSearchAlertRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.unsubscribeProactiveSearchAlertRaw(
      requestParameters,
      initOverrides,
    );
  }
}
