/**
 *
 */
export enum PaymentTypeId {
  Ideal = 1,
  CreditCard = 2,
  Paypal = 3,
  Other = 4,
  EMandate = 21,
}
