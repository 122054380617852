import * as Sentry from '@sentry/nextjs';
import { CaptureContext } from '@sentry/types';
import { Locale } from '@kamernet/core/Intl/types';
import { logger } from '@kamernet/core/Logger';
import { UserProfile } from '@kamernet/core/ApiClient/Kamernet';

export function setUserContextForErrorReporting(
  user: Pick<UserProfile, 'useremail'>,
  locale: Locale,
  anonymousId: string | null,
) {
  Sentry.setUser({
    email: user.useremail?.toString(),
    locale,
    anonymousId,
  });
}
export function setCustomErrorTag(tagName: string, value: string) {
  Sentry.setTag(tagName, value);
}

export function reportError(error: Error, captureContext?: CaptureContext) {
  const { message, stack, ...rest } = error;
  const sentryEventId = Sentry.captureException(error, captureContext);
  logger.error({
    message,
    stack,
    sentryEventId,
    associatedEvent: `https://sentry.io/housinganywhere/kamernet-nextjs/events/${sentryEventId}`,
    context: captureContext,
    ...rest,
  });
}
