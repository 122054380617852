import { AppContext } from 'next/app';
import { DEFAULT_FEATURE_FLAGS, FeatureFlags } from './types';

export function initFeatureFlagsBrowserApp(
  appContext: AppContext,
): FeatureFlags {
  /**
   * interpret data passed form server
   */
  const featureFlags: FeatureFlags | null | undefined =
    appContext.router.components[appContext.router.route]?.props?.featureFlags;

  return featureFlags || DEFAULT_FEATURE_FLAGS;
}
