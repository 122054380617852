/**
 *
 */
export enum ConversationStatus {
  All = 0,
  Normal = 1,
  Trash = 2,
  Deleted = 3,
  PossibleAbuse = 4,
}
