import { Mutex } from 'async-mutex';

import { ProcessExitCode } from '@kamernet/constants/ProcessExitCode';
import { initializeServerDataCache } from '@kamernet/core/DataCache/initializeServerDataCache';
import { reportError } from '@kamernet/core/Errors';
import { CustomTags, ErrorModules } from '@kamernet/core/Errors/types';
import { initializeFlagsmithServerClient } from '@kamernet/core/FeatureFlags/initializeFlagsmithServerClient';
import { logger } from '@kamernet/core/Logger';

const bootstrapServerMutex = new Mutex();

let isBootstrapDone = false;

export async function bootstrapServer(): Promise<void> {
  try {
    if (typeof window !== 'undefined') {
      throw new Error('Bootstrap server is not allowed in the browser');
    }

    const release = await bootstrapServerMutex.acquire();
    if (!isBootstrapDone) {
      logger.info('bootstrapping server...');
      initializeFlagsmithServerClient();
      await Promise.all([initializeServerDataCache()]);
      isBootstrapDone = true;
    }
    release();
  } catch (error) {
    reportError(new Error('Failed to bootstrap server'), {
      extra: {
        cause: error,
      },
      tags: {
        [CustomTags.KN_Module]: ErrorModules.BootstrapServer,
      },
    });

    process.exit(ProcessExitCode.BOOTSTRAP_SERVER_ERROR);
  }
}
