/**
 *
 */
export enum SuitableForNumberID {
  OnePerson = 1,
  TwoPersons = 2,
  ThreePersons = 4,
  FourPersons = 8,
  FivePersons = 16,
  MoreThanFivePersons = 17,
}
