export type EnumObject = { [key: string]: number | string };

export type EnumValues<Enum extends EnumObject> = Enum extends {
  [key: string]: infer Value | string;
}
  ? Value
  : never;

export const Enum = {
  keys<Enum extends EnumObject>(enumObject: Enum): (keyof Enum)[] {
    return Object.keys(enumObject).filter(key => Number.isNaN(Number(key)));
  },
  values<Enum extends EnumObject>(enumObject: Enum): EnumValues<Enum>[] {
    return Enum.keys(enumObject).map(
      key => enumObject[key] as EnumValues<Enum>,
    );
  },
};
