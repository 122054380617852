import { ApiClientBase } from '@kamernet/core/ApiClient/ApiClientBase';

import {
  OAUTH_REFRESH_ACCESS_TOKEN_API_ROUTE,
  OauthRefreshAccessTokenResponse,
} from '.';

export function oauthRefreshAccessToken(
  this: ApiClientBase,
  userAgent: string | undefined,
) {
  return this.handleResponse<OauthRefreshAccessTokenResponse>(
    fetch(`${this.kamernetWebOrigin}${OAUTH_REFRESH_ACCESS_TOKEN_API_ROUTE}`, {
      method: 'GET',
      headers: {
        ...this.headers,
        ...(userAgent && { 'User-Agent': userAgent }),
      },
    }),
  );
}
