import type {
  CreateReminderPaymentIntentResponse,
  GetPaymentStepFinalResponse,
  GetPaymentStepThreeResponse,
  GetPaymentStepTwoResponse,
  GetPremiumProductsResponse,
  GetSupplyProductsResponse,
  MakePaymentStepFinalPayload,
  MakePaymentStepFinalResponse,
  MakePaymentStepOneResponse,
  MakePaymentStepThreePayload,
  MakePaymentStepThreeResponse,
  MakePaymentStepTwoPayload,
  MakePaymentStepTwoResponse,
  ProcessPaymentTransactionPayload,
  ProcessPaymentTransactionResponse,
  ProductID,
  ValidateAddressPayload,
  ValidateAddressResponse,
} from '../models/index';
import * as runtime from '../runtime';

export interface CreateReminderPaymentIntentRequest {
  paymentIntentId: string;
}

export interface GetPaymentStepFinalRequest {
  selectedProduct?: string;
  selectedProductRatePlan?: string;
}

export interface GetPaymentStepTwoRequest {
  selectedProduct?: string;
  selectedProductRatePlan?: string;
}

export interface GetSupplyProductsRequest {
  listingID: number;
}

export interface MakePaymentStepFinalRequest {
  makePaymentStepFinalPayload: MakePaymentStepFinalPayload;
}

export interface MakePaymentStepOneRequest {
  selectedProduct?: string;
  selectedProductRatePlan?: string;
}

export interface MakePaymentStepThreeRequest {
  makePaymentStepThreePayload: MakePaymentStepThreePayload;
}

export interface MakePaymentStepTwoRequest {
  makePaymentStepTwoPayload: MakePaymentStepTwoPayload;
}

export interface ProcessBuckarooTransactionRequest {
  processPaymentTransactionPayload: ProcessPaymentTransactionPayload;
}

export interface ProcessMandateTransactionRequest {
  processPaymentTransactionPayload: ProcessPaymentTransactionPayload;
}

export interface ProcessMandateTransactionCancelledRequest {
  processPaymentTransactionPayload: ProcessPaymentTransactionPayload;
}

export interface ProcessMandateTransactionErrorRequest {
  processPaymentTransactionPayload: ProcessPaymentTransactionPayload;
}

export interface ProcessMandateTransactionRejectRequest {
  processPaymentTransactionPayload: ProcessPaymentTransactionPayload;
}

export interface SelectSupplyProductsRequest {
  listingID: number;
  selectedProductIdList: Array<ProductID>;
}

export interface ValidateAddressRequest {
  validateAddressPayload: ValidateAddressPayload;
}

/**
 *
 */
export class PaymentApi extends runtime.BaseAPI {
  /**
   */
  async createReminderPaymentIntentRaw(
    requestParameters: CreateReminderPaymentIntentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateReminderPaymentIntentResponse>> {
    if (requestParameters.paymentIntentId == null) {
      throw new runtime.RequiredError(
        'paymentIntentId',
        'Required parameter "paymentIntentId" was null or undefined when calling createReminderPaymentIntent().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.paymentIntentId != null) {
      queryParameters.paymentIntentId = requestParameters.paymentIntentId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/stripe/createreminderpaymentintent`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Create Reminder Payment Intent
   */
  async createReminderPaymentIntent(
    requestParameters: CreateReminderPaymentIntentRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateReminderPaymentIntentResponse> {
    const response = await this.createReminderPaymentIntentRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getAfterpayRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetPaymentStepTwoResponse>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/premium/tenantafterpay`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Payment flow afterpay scenario
   */
  async getAfterpay(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetPaymentStepTwoResponse> {
    const response = await this.getAfterpayRaw(initOverrides);
    return response.value();
  }

  /**
   */
  async getPaymentStepFinalRaw(
    requestParameters: GetPaymentStepFinalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetPaymentStepFinalResponse>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.selectedProduct != null) {
      queryParameters.selectedProduct = requestParameters.selectedProduct;
    }

    if (requestParameters.selectedProductRatePlan != null) {
      queryParameters.selectedProductRatePlan =
        requestParameters.selectedProductRatePlan;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/premium/paymentstepfinal`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get Payment billing details and payment info
   */
  async getPaymentStepFinal(
    requestParameters: GetPaymentStepFinalRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetPaymentStepFinalResponse> {
    const response = await this.getPaymentStepFinalRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getPaymentStepThreeRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetPaymentStepThreeResponse>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/premium/paymentstep3`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get Payment methods - step 3
   */
  async getPaymentStepThree(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetPaymentStepThreeResponse> {
    const response = await this.getPaymentStepThreeRaw(initOverrides);
    return response.value();
  }

  /**
   */
  async getPaymentStepTwoRaw(
    requestParameters: GetPaymentStepTwoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetPaymentStepTwoResponse>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.selectedProduct != null) {
      queryParameters.selectedProduct = requestParameters.selectedProduct;
    }

    if (requestParameters.selectedProductRatePlan != null) {
      queryParameters.selectedProductRatePlan =
        requestParameters.selectedProductRatePlan;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/premium/paymentstep2`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Payment flow step two
   */
  async getPaymentStepTwo(
    requestParameters: GetPaymentStepTwoRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetPaymentStepTwoResponse> {
    const response = await this.getPaymentStepTwoRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getPremiumProductsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetPremiumProductsResponse>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/premium/products`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get the list of premium products.
   */
  async getPremiumProducts(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetPremiumProductsResponse> {
    const response = await this.getPremiumProductsRaw(initOverrides);
    return response.value();
  }

  /**
   */
  async getSupplyProductsRaw(
    requestParameters: GetSupplyProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetSupplyProductsResponse>> {
    if (requestParameters.listingID == null) {
      throw new runtime.RequiredError(
        'listingID',
        'Required parameter "listingID" was null or undefined when calling getSupplyProducts().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.listingID != null) {
      queryParameters.listingID = requestParameters.listingID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/premium/supplyproducts`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get the list of supply products.
   */
  async getSupplyProducts(
    requestParameters: GetSupplyProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetSupplyProductsResponse> {
    const response = await this.getSupplyProductsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async makePaymentStepFinalRaw(
    requestParameters: MakePaymentStepFinalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MakePaymentStepFinalResponse>> {
    if (requestParameters.makePaymentStepFinalPayload == null) {
      throw new runtime.RequiredError(
        'makePaymentStepFinalPayload',
        'Required parameter "makePaymentStepFinalPayload" was null or undefined when calling makePaymentStepFinal().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/premium/paymentstepfinal`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.makePaymentStepFinalPayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Post user billing details and selected payment method
   */
  async makePaymentStepFinal(
    requestParameters: MakePaymentStepFinalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MakePaymentStepFinalResponse> {
    const response = await this.makePaymentStepFinalRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async makePaymentStepOneRaw(
    requestParameters: MakePaymentStepOneRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MakePaymentStepOneResponse>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.selectedProduct != null) {
      queryParameters.selectedProduct = requestParameters.selectedProduct;
    }

    if (requestParameters.selectedProductRatePlan != null) {
      queryParameters.selectedProductRatePlan =
        requestParameters.selectedProductRatePlan;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/premium/paymentstep1`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * make payment - step One
   */
  async makePaymentStepOne(
    requestParameters: MakePaymentStepOneRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MakePaymentStepOneResponse> {
    const response = await this.makePaymentStepOneRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async makePaymentStepThreeRaw(
    requestParameters: MakePaymentStepThreeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MakePaymentStepThreeResponse>> {
    if (requestParameters.makePaymentStepThreePayload == null) {
      throw new runtime.RequiredError(
        'makePaymentStepThreePayload',
        'Required parameter "makePaymentStepThreePayload" was null or undefined when calling makePaymentStepThree().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/premium/paymentstep3`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.makePaymentStepThreePayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Post user selected payment method
   */
  async makePaymentStepThree(
    requestParameters: MakePaymentStepThreeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MakePaymentStepThreeResponse> {
    const response = await this.makePaymentStepThreeRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async makePaymentStepTwoRaw(
    requestParameters: MakePaymentStepTwoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<MakePaymentStepTwoResponse>> {
    if (requestParameters.makePaymentStepTwoPayload == null) {
      throw new runtime.RequiredError(
        'makePaymentStepTwoPayload',
        'Required parameter "makePaymentStepTwoPayload" was null or undefined when calling makePaymentStepTwo().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/premium/paymentstep2`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.makePaymentStepTwoPayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * make payment - step Two
   */
  async makePaymentStepTwo(
    requestParameters: MakePaymentStepTwoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<MakePaymentStepTwoResponse> {
    const response = await this.makePaymentStepTwoRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async processBuckarooTransactionRaw(
    requestParameters: ProcessBuckarooTransactionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProcessPaymentTransactionResponse>> {
    if (requestParameters.processPaymentTransactionPayload == null) {
      throw new runtime.RequiredError(
        'processPaymentTransactionPayload',
        'Required parameter "processPaymentTransactionPayload" was null or undefined when calling processBuckarooTransaction().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/premium/processbuckaroo`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.processPaymentTransactionPayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Process buckaroo transaction
   */
  async processBuckarooTransaction(
    requestParameters: ProcessBuckarooTransactionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProcessPaymentTransactionResponse> {
    const response = await this.processBuckarooTransactionRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async processMandateTransactionRaw(
    requestParameters: ProcessMandateTransactionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProcessPaymentTransactionResponse>> {
    if (requestParameters.processPaymentTransactionPayload == null) {
      throw new runtime.RequiredError(
        'processPaymentTransactionPayload',
        'Required parameter "processPaymentTransactionPayload" was null or undefined when calling processMandateTransaction().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/premium/processMandate`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.processPaymentTransactionPayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Process mandate transaction
   */
  async processMandateTransaction(
    requestParameters: ProcessMandateTransactionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProcessPaymentTransactionResponse> {
    const response = await this.processMandateTransactionRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async processMandateTransactionCancelledRaw(
    requestParameters: ProcessMandateTransactionCancelledRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProcessPaymentTransactionResponse>> {
    if (requestParameters.processPaymentTransactionPayload == null) {
      throw new runtime.RequiredError(
        'processPaymentTransactionPayload',
        'Required parameter "processPaymentTransactionPayload" was null or undefined when calling processMandateTransactionCancelled().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/premium/processMandateCancelled`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.processPaymentTransactionPayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Process mandate transaction when a cancelled happens
   */
  async processMandateTransactionCancelled(
    requestParameters: ProcessMandateTransactionCancelledRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProcessPaymentTransactionResponse> {
    const response = await this.processMandateTransactionCancelledRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async processMandateTransactionErrorRaw(
    requestParameters: ProcessMandateTransactionErrorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProcessPaymentTransactionResponse>> {
    if (requestParameters.processPaymentTransactionPayload == null) {
      throw new runtime.RequiredError(
        'processPaymentTransactionPayload',
        'Required parameter "processPaymentTransactionPayload" was null or undefined when calling processMandateTransactionError().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/premium/processMandateError`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.processPaymentTransactionPayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Process mandate transaction when an error happens
   */
  async processMandateTransactionError(
    requestParameters: ProcessMandateTransactionErrorRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProcessPaymentTransactionResponse> {
    const response = await this.processMandateTransactionErrorRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async processMandateTransactionRejectRaw(
    requestParameters: ProcessMandateTransactionRejectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ProcessPaymentTransactionResponse>> {
    if (requestParameters.processPaymentTransactionPayload == null) {
      throw new runtime.RequiredError(
        'processPaymentTransactionPayload',
        'Required parameter "processPaymentTransactionPayload" was null or undefined when calling processMandateTransactionReject().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/premium/processMandateReject`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.processPaymentTransactionPayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Process mandate transaction when a reject happens
   */
  async processMandateTransactionReject(
    requestParameters: ProcessMandateTransactionRejectRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ProcessPaymentTransactionResponse> {
    const response = await this.processMandateTransactionRejectRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async selectSupplyProductsRaw(
    requestParameters: SelectSupplyProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.listingID == null) {
      throw new runtime.RequiredError(
        'listingID',
        'Required parameter "listingID" was null or undefined when calling selectSupplyProducts().',
      );
    }

    if (requestParameters.selectedProductIdList == null) {
      throw new runtime.RequiredError(
        'selectedProductIdList',
        'Required parameter "selectedProductIdList" was null or undefined when calling selectSupplyProducts().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'application/x-www-form-urlencoded' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: unknown): unknown };
    const useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.listingID != null) {
      formParams.append('listingID', requestParameters.listingID as unknown);
    }

    if (requestParameters.selectedProductIdList != null) {
      requestParameters.selectedProductIdList.forEach(element => {
        formParams.append('selectedProductIdList', element as unknown);
      });
    }

    const response = await this.request(
      {
        path: `/premium/supplyproducts`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Select supply products
   */
  async selectSupplyProducts(
    requestParameters: SelectSupplyProductsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.selectSupplyProductsRaw(requestParameters, initOverrides);
  }

  /**
   */
  async validateAddressRaw(
    requestParameters: ValidateAddressRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ValidateAddressResponse>> {
    if (requestParameters.validateAddressPayload == null) {
      throw new runtime.RequiredError(
        'validateAddressPayload',
        'Required parameter "validateAddressPayload" was null or undefined when calling validateAddress().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/premium/address`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.validateAddressPayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Address validation
   */
  async validateAddress(
    requestParameters: ValidateAddressRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ValidateAddressResponse> {
    const response = await this.validateAddressRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }
}
