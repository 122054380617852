import {
  MyConversationsInboxType,
  SortConversationsBy,
} from '@kamernet/core/ApiClient/Kamernet';
import {
  parseRouteParamToEnum,
  parseRouteParamToInt,
} from '@kamernet/utilities/URL';

import { defineRoute } from '../defineRoute';

export const DEFAULT_INBOX_TYPE = MyConversationsInboxType.Default;
export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_ROWS_PER_PAGE = 20;
export const DEFAULT_SORT_BY = SortConversationsBy.Recommended;
export const DEFAULT_LISTING_ID = 0;

export type MyConversationsRouteParams = {
  inboxType: MyConversationsInboxType;
  pageNo?: number | null;
  rowsPerPage?: number | null;
  sortBy?: SortConversationsBy | null;
  listingID?: number | null;
};

export type MyConversationsRouteParamsString = {
  inboxType: string;
  pageNo?: string | null;
  rowsPerPage?: string | null;
  sortBy?: string | null;
  listingID?: string | null;
};

export const MyConversations = defineRoute<
  MyConversationsRouteParams,
  MyConversationsRouteParamsString
>({
  pathname: '/my-messages/[inboxType]',
  nlPathname: '/mijn-berichten/[inboxType]',
  routeParamsStringifier: routeParams => {
    const inboxTypeMap: Record<MyConversationsInboxType, string> = {
      [MyConversationsInboxType.Default]: 'default',
      [MyConversationsInboxType.All]: 'all',
      [MyConversationsInboxType.Favorite]: 'favorite',
      [MyConversationsInboxType.Trash]: 'trash',
      [MyConversationsInboxType.ActiveListings]: 'activeadverts',
      [MyConversationsInboxType.InactiveListings]: 'inactiveadverts',
      [MyConversationsInboxType.Unreplied]: 'unreplied',
    };

    return {
      inboxType: inboxTypeMap[routeParams.inboxType],
      pageNo: routeParams.pageNo?.toString(),
      rowsPerPage: routeParams.rowsPerPage?.toString(),
      sortBy: routeParams.sortBy?.toString(),
      listingID: routeParams.listingID?.toString(),
    };
  },
  routeParamsParser: routeParamsString => {
    const inboxTypeMap: Record<string, MyConversationsInboxType> = {
      default: MyConversationsInboxType.Default,
      all: MyConversationsInboxType.All,
      trash: MyConversationsInboxType.Trash,
      favorite: MyConversationsInboxType.Favorite,
      activeadverts: MyConversationsInboxType.ActiveListings,
      inactiveadverts: MyConversationsInboxType.InactiveListings,
      unreplied: MyConversationsInboxType.Unreplied,
    };

    return {
      inboxType:
        inboxTypeMap[routeParamsString.inboxType.toLowerCase()] ??
        DEFAULT_INBOX_TYPE,
      pageNo: parseRouteParamToInt(routeParamsString.pageNo) || DEFAULT_PAGE_NO,
      rowsPerPage:
        parseRouteParamToInt(routeParamsString.rowsPerPage) ||
        DEFAULT_ROWS_PER_PAGE,
      sortBy:
        parseRouteParamToEnum(routeParamsString.sortBy, SortConversationsBy) ??
        DEFAULT_SORT_BY,
      listingID:
        parseRouteParamToInt(routeParamsString.listingID) || DEFAULT_LISTING_ID,
    };
  },
});
