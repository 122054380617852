import React from 'react';

import { AppServicesContext } from '@kamernet/core/AppServices';

import { logger } from '../Logger';

import { ServiceWorkerMessage } from './types';

function postMessage(message: ServiceWorkerMessage): void {
  navigator.serviceWorker.controller?.postMessage(message);
}

export const ServiceWorkerSetup = () => {
  const { featureFlags } = AppServicesContext.useContext();
  const useIDP = featureFlags.use_idp === 'on';

  React.useEffect(() => {
    function onControllerChange(): void {
      postMessage({ useIDP });
    }

    if ('serviceWorker' in navigator) {
      postMessage({ useIDP });

      navigator.serviceWorker.addEventListener(
        'controllerchange',
        onControllerChange,
      );
    }

    return () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.removeEventListener(
          'controllerchange',
          onControllerChange,
        );
      }
    };
  }, [useIDP]);

  React.useEffect(() => {
    const register = async () => {
      if ('serviceWorker' in navigator) {
        await navigator.serviceWorker.register('/sw.js', {
          scope: '/',
        });
      }
    };

    register().catch(() => {
      logger.error('Error in setting up the service worker');
    });
  }, []);

  return null;
};
