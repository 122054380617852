import type {
  GetAutoReplyResponse,
  GetMatchScoreDetailsResponse,
  GetMyConversationsResponse,
  GetMyListingsConversationsResponse,
  MarkConversationsRequest,
  MyConversationsInboxType,
  SendMessageRequest,
  SetAutoReplyRequest,
  SortConversationsBy,
} from '../models/index';
import * as runtime from '../runtime';

export interface GetAutoReplyRequest {
  studentHouseID?: number;
}

export interface GetMatchScoreDetailsRequest {
  conversationID: number;
}

export interface GetMyConversationsRequest {
  pageNo: number;
  rowsPerPage: number;
  sortBy: SortConversationsBy;
  inboxType: MyConversationsInboxType;
  listingID?: number;
}

export interface GetMyListingsConversationsRequest {
  pageNo: number;
  rowsPerPage: number;
  inboxType: MyConversationsInboxType;
}

export interface MarkAsRequest {
  markConversationsRequest: MarkConversationsRequest;
}

export interface SendMessageOperationRequest {
  sendMessageRequest: SendMessageRequest;
}

export interface SetAutoReplyOperationRequest {
  setAutoReplyRequest: SetAutoReplyRequest;
}

/**
 *
 */
export class ConversationApi extends runtime.BaseAPI {
  /**
   */
  async getAutoReplyRaw(
    requestParameters: GetAutoReplyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetAutoReplyResponse>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.studentHouseID != null) {
      queryParameters.studentHouseID = requestParameters.studentHouseID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/conversation/auto-reply`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get current auto reply
   */
  async getAutoReply(
    requestParameters: GetAutoReplyRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetAutoReplyResponse> {
    const response = await this.getAutoReplyRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getMatchScoreDetailsRaw(
    requestParameters: GetMatchScoreDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetMatchScoreDetailsResponse>> {
    if (requestParameters.conversationID == null) {
      throw new runtime.RequiredError(
        'conversationID',
        'Required parameter "conversationID" was null or undefined when calling getMatchScoreDetails().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.conversationID != null) {
      queryParameters.conversationID = requestParameters.conversationID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/conversation/match-score-details`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get match score details
   */
  async getMatchScoreDetails(
    requestParameters: GetMatchScoreDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetMatchScoreDetailsResponse> {
    const response = await this.getMatchScoreDetailsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getMyConversationsRaw(
    requestParameters: GetMyConversationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetMyConversationsResponse>> {
    if (requestParameters.pageNo == null) {
      throw new runtime.RequiredError(
        'pageNo',
        'Required parameter "pageNo" was null or undefined when calling getMyConversations().',
      );
    }

    if (requestParameters.rowsPerPage == null) {
      throw new runtime.RequiredError(
        'rowsPerPage',
        'Required parameter "rowsPerPage" was null or undefined when calling getMyConversations().',
      );
    }

    if (requestParameters.sortBy == null) {
      throw new runtime.RequiredError(
        'sortBy',
        'Required parameter "sortBy" was null or undefined when calling getMyConversations().',
      );
    }

    if (requestParameters.inboxType == null) {
      throw new runtime.RequiredError(
        'inboxType',
        'Required parameter "inboxType" was null or undefined when calling getMyConversations().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.pageNo != null) {
      queryParameters.pageNo = requestParameters.pageNo;
    }

    if (requestParameters.rowsPerPage != null) {
      queryParameters.rowsPerPage = requestParameters.rowsPerPage;
    }

    if (requestParameters.sortBy != null) {
      queryParameters.sortBy = requestParameters.sortBy;
    }

    if (requestParameters.inboxType != null) {
      queryParameters.inboxType = requestParameters.inboxType;
    }

    if (requestParameters.listingID != null) {
      queryParameters.listingID = requestParameters.listingID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/conversation/my-conversations`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get my conversations
   */
  async getMyConversations(
    requestParameters: GetMyConversationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetMyConversationsResponse> {
    const response = await this.getMyConversationsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getMyListingsConversationsRaw(
    requestParameters: GetMyListingsConversationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetMyListingsConversationsResponse>> {
    if (requestParameters.pageNo == null) {
      throw new runtime.RequiredError(
        'pageNo',
        'Required parameter "pageNo" was null or undefined when calling getMyListingsConversations().',
      );
    }

    if (requestParameters.rowsPerPage == null) {
      throw new runtime.RequiredError(
        'rowsPerPage',
        'Required parameter "rowsPerPage" was null or undefined when calling getMyListingsConversations().',
      );
    }

    if (requestParameters.inboxType == null) {
      throw new runtime.RequiredError(
        'inboxType',
        'Required parameter "inboxType" was null or undefined when calling getMyListingsConversations().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.pageNo != null) {
      queryParameters.pageNo = requestParameters.pageNo;
    }

    if (requestParameters.rowsPerPage != null) {
      queryParameters.rowsPerPage = requestParameters.rowsPerPage;
    }

    if (requestParameters.inboxType != null) {
      queryParameters.inboxType = requestParameters.inboxType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/conversation/listings`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get my listings conversations
   */
  async getMyListingsConversations(
    requestParameters: GetMyListingsConversationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetMyListingsConversationsResponse> {
    const response = await this.getMyListingsConversationsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async markAsRaw(
    requestParameters: MarkAsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.markConversationsRequest == null) {
      throw new runtime.RequiredError(
        'markConversationsRequest',
        'Required parameter "markConversationsRequest" was null or undefined when calling markAs().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/conversation/mark`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.markConversationsRequest,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Marks conversations as for example deleted, favorite, ...
   */
  async markAs(
    requestParameters: MarkAsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.markAsRaw(requestParameters, initOverrides);
  }

  /**
   */
  async sendMessageRaw(
    requestParameters: SendMessageOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.sendMessageRequest == null) {
      throw new runtime.RequiredError(
        'sendMessageRequest',
        'Required parameter "sendMessageRequest" was null or undefined when calling sendMessage().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/conversation/send-message`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.sendMessageRequest,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Set message
   */
  async sendMessage(
    requestParameters: SendMessageOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendMessageRaw(requestParameters, initOverrides);
  }

  /**
   */
  async setAutoReplyRaw(
    requestParameters: SetAutoReplyOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.setAutoReplyRequest == null) {
      throw new runtime.RequiredError(
        'setAutoReplyRequest',
        'Required parameter "setAutoReplyRequest" was null or undefined when calling setAutoReply().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/conversation/auto-reply`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.setAutoReplyRequest,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Set auto reply
   */
  async setAutoReply(
    requestParameters: SetAutoReplyOperationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.setAutoReplyRaw(requestParameters, initOverrides);
  }
}
