import { createTheme } from '@mui/material/styles';

import { match } from 'css-mediaquery';

import { Locale } from '@kamernet/core/Intl/types';
import { MuiLocalesMap } from '@kamernet/core/MUI/MuiLocalesMap';
import { DeviceType } from '@kamernet/utilities/UserAgent/types';

import { getSSRScreenWidth } from './getSSRScreenWidth';
import { MuiThemeConfig } from './theme.mui-config';

export function makeTheme(locale: Locale, deviceType: DeviceType) {
  const ssrMatchMedia = (query: string) => ({
    matches: match(query, {
      // The estimated CSS width of the browser.
      width: getSSRScreenWidth(deviceType),
    }),
  });

  const extendedThemeConfig = {
    ...MuiThemeConfig,
    components: {
      ...MuiThemeConfig.components,
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia,
        },
      },
    },
  };

  const value = createTheme(extendedThemeConfig, MuiLocalesMap[locale]);

  return value;
}
