/**
 *
 */
export enum RoomAbuseType {
  Spam = 1,
  Scam = 2,
  Agency = 3,
  InappropriateBehavior = 4,
  NoShow = 5,
  Other = 6,
}
