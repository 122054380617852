import type { CitySlugResponse, StreetSlugResponse } from '../models/index';
import * as runtime from '../runtime';

export interface GetCityBySlugRequest {
  citySlugId: string;
}

export interface GetStreetBySlugRequest {
  streetSlugId: string;
  cityId: string;
}

/**
 *
 */
export class GisApi extends runtime.BaseAPI {
  /**
   */
  async getCityBySlugRaw(
    requestParameters: GetCityBySlugRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CitySlugResponse>> {
    if (requestParameters.citySlugId == null) {
      throw new runtime.RequiredError(
        'citySlugId',
        'Required parameter "citySlugId" was null or undefined when calling getCityBySlug().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/gis/slugs/{citySlugId}`.replace(
          `{${'citySlugId'}}`,
          encodeURIComponent(String(requestParameters.citySlugId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Gets the city details for the given city slug
   */
  async getCityBySlug(
    requestParameters: GetCityBySlugRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CitySlugResponse> {
    const response = await this.getCityBySlugRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getStreetBySlugRaw(
    requestParameters: GetStreetBySlugRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<StreetSlugResponse>> {
    if (requestParameters.streetSlugId == null) {
      throw new runtime.RequiredError(
        'streetSlugId',
        'Required parameter "streetSlugId" was null or undefined when calling getStreetBySlug().',
      );
    }

    if (requestParameters.cityId == null) {
      throw new runtime.RequiredError(
        'cityId',
        'Required parameter "cityId" was null or undefined when calling getStreetBySlug().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.cityId != null) {
      queryParameters.cityId = requestParameters.cityId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/gis/slugs/{streetSlugId}`.replace(
          `{${'streetSlugId'}}`,
          encodeURIComponent(String(requestParameters.streetSlugId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Gets the street details for the given street slug
   */
  async getStreetBySlug(
    requestParameters: GetStreetBySlugRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<StreetSlugResponse> {
    const response = await this.getStreetBySlugRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }
}
