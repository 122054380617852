import getConfig from 'next/config';

import FlagsmithServerClient from 'flagsmith-nodejs';

import { logger } from '@kamernet/core/Logger';

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

let flagsmithServerClient: FlagsmithServerClient;

export function initializeFlagsmithServerClient(): void {
  if (flagsmithServerClient) {
    logger.warn('initializeFlagsmithServerClient is called more than once');
    return;
  }

  flagsmithServerClient = new FlagsmithServerClient({
    apiUrl: serverRuntimeConfig.FLAGSMITH_API_URL,
    environmentKey: serverRuntimeConfig.FLAGSMITH_ENVIRONMENT_KEY,
    enableLocalEvaluation:
      serverRuntimeConfig.FLAGSMITH_ENABLE_LOCAL_EVALUATION,
    requestTimeoutSeconds:
      serverRuntimeConfig.FLAGSMITH_REQUEST_TIMEOUT_SECONDS,
    customHeaders: {
      'X-Application-Name': 'kamernet-nextjs',
      'X-Environment': publicRuntimeConfig.DEPLOYMENT_ID,
      'User-Agent': `kamernet-nextjs,${publicRuntimeConfig.DEPLOYMENT_ID}`, // this is a fallback in case the X-Application-Name and X-Environment don't work
    },
  });

  logger.info('A new FlagsmithClient instance is created');
}

export function getFlagsmithServerClient(): FlagsmithServerClient {
  return flagsmithServerClient;
}
