import getConfig from 'next/config';

import { init } from '@hbf/analytics';

import { reportError } from '@kamernet/core/Errors';

const { publicRuntimeConfig } = getConfig();

const isBrowser = typeof global.window !== 'undefined';

export function initAnalytics() {
  if (isBrowser && publicRuntimeConfig.RUDDERSTACK_WRITE_KEY) {
    init({
      rudderstack: {
        writeKey: publicRuntimeConfig.RUDDERSTACK_WRITE_KEY,
        dataPlaneUrl: publicRuntimeConfig.RUDDERSTACK_DATA_PLANE_URL,
        configUrl: publicRuntimeConfig.RUDDERSTACK_CONFIG_URL,
        cdnUrl: publicRuntimeConfig.RUDDERSTACK_CDN_URL,
        loadOptions: {
          consentManagement: {
            enabled: true,
            provider: 'oneTrust',
          },
        },
      },
    })?.catch(reportError);
  }
}
