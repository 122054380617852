import type { ErrorEvent, EventHint } from '@sentry/types';

export const filteredIssuesRegex: RegExp[] = [
  /<!DOCTYPE HTML PUBLIC "-\/\/W3C\/\/DTD/,
  /String was not recognized as a valid Boolean/,
  /Errors.GeneralErrorCode.http_400/,
  // This unhandled error is from third party flipbase video player.min.js. It gets logged in browser console only while user does fast forwarding of videos.
  // It does not have any impact and fast forwarding works as expected. It also happens in flipbase official documentation of demo video.
  /^procent is not defined$/,
  /Can't find variable: procent/,
  // These console errors is from abort controller. it does not have any technical impact.
  /The user aborted a request./,

  // This is expected behaviour due to CSP security policy we enabled which prevents framing our site in another sites.
  /Blocked a frame with origin "https:\/\/kamernet\.nl" from accessing a cross-origin frame\. Protocols, domains, and ports must match\./,

  // These known errors does not have any impact for now. but can be revissed it later.
  /The request failed and the interceptors did not return an alternative response/,
  /Non-Error promise rejection captured with value: Timeout/,
  /Unexpected end of input/,
  /The object can not be found here./,
  /Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node./,
  /SyntaxError: Unexpected token '='. Expected an opening \\(\\) before a method's parameter list\\./,
  /^undefined is not an object \(evaluating 'a\.L'\)$/,
  /^AbortError: Fetch is aborted$/,
  /^Non-Error promise rejection captured with value: Timeout$/,
  /Failed to fetch user profile due to timeout error/, // This error has already a monitor alert in GCP. Sentry reports are not needed
];

export const sampleCommonErrors =
  (sampleCommonErrorsRate: number) =>
  (event: ErrorEvent, hint: EventHint): ErrorEvent | null => {
    const error = hint.originalException;
    if (
      error instanceof Error &&
      // eslint-disable-next-line custom/no-restricted-error-message
      filteredIssuesRegex.some(IssueRegex => IssueRegex.test(error.message))
    ) {
      return Math.random() < sampleCommonErrorsRate ? event : null;
    }

    return event;
  };
