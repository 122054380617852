/**
 *
 */
export enum RadiusID {
  Km0 = 1,
  Km1 = 2,
  Km2 = 3,
  Km5 = 4,
  Km10 = 5,
  Km15 = 6,
  Km20 = 7,
  Km30 = 8,
  Km50 = 9,
}
