export enum AppCookieName {
  LEGACY_AUTH = 'logonUser',
  LEGACY_AUTH_FALLBACK = 'USER_EMAIL',
  SESSION = 'ASP.NET_SessionId',
  PRIVACY_POLICY = 'cookie_Policy',
  LOAD_BALANCER = 'GCLB',
  ANONYMOUS_ID = 'ha_anonymous_id',
  OAUTH_ACCESS_TOKEN = '__ha_at',
  OAUTH_REFRESH_TOKEN = '__ha_rt',
  OAUTH_CODE_VERIDIFER_TOKEN = '__ha_cv',
  OAUTH_STATE_TOKEN = '__ha_s',
}
