import { Locale } from '@kamernet/core/Intl/types';

import {
  Route,
  RouteParamsStringifier,
  RouteParamsParser,
  RouteParams,
  RouteParamsString,
} from './types';

export function defineRoute<
  TRouteParams extends RouteParams = RouteParams,
  TRouteParamsString extends RouteParamsString = RouteParamsString,
>({
  pathname,
  nlPathname,
  routeParamsStringifier,
  routeParamsParser,
  isLandlordRoute,
}: {
  pathname: string;
  nlPathname?: string;
  // @todo: this property should be required after all routes use stringifier and parser
  routeParamsStringifier?: RouteParamsStringifier<
    TRouteParams,
    TRouteParamsString
  >;
  // @todo: this property should be required after all routes use stringifier and parser
  routeParamsParser?: RouteParamsParser<TRouteParamsString, TRouteParams>;
  isLandlordRoute?: boolean;
}): Route<TRouteParams, TRouteParamsString> {
  const pathnameByLocale: Record<Locale, string> = {
    [Locale.en]: pathname,
    [Locale.nl]: nlPathname || pathname,
  };

  return Object.freeze({
    pathname,
    pathnameByLocale,
    isLandlordRoute: isLandlordRoute ?? false,

    url(params, locale, localeSerialization) {
      let url = locale ? pathnameByLocale[locale] || pathname : pathname;

      const urlSearchParams = new URLSearchParams();

      const stringifiedRouteParams = routeParamsStringifier
        ? routeParamsStringifier(params, locale)
        : (params as RouteParamsString);

      Object.entries(stringifiedRouteParams).forEach(([key, val]) => {
        if (!val?.trim()) return;

        if (url.includes(`[${key}]`)) {
          url = url.replace(`[${key}]`, val);
        } else {
          urlSearchParams.append(key, val);
        }
      });

      if (localeSerialization === 'query') {
        urlSearchParams.append('locale', locale);
      }

      const urlSearchParamsString = urlSearchParams.toString();

      return (
        (localeSerialization === 'prefix' && locale !== Locale.nl
          ? `/${locale}`
          : '') +
        url +
        (urlSearchParamsString ? `?${urlSearchParamsString}` : '')
      );
    },

    stringifyRouteParams(routeParams, locale) {
      if (!routeParamsStringifier) {
        throw new Error('routeParamsStringifier is not defined');
      }

      return routeParamsStringifier(routeParams, locale);
    },

    parseRouteParams(routeParamsString, locale) {
      if (!routeParamsParser) {
        throw new Error('routeParamsParser is not defined');
      }

      return routeParamsParser(routeParamsString, locale);
    },
  } as Route<TRouteParams, TRouteParamsString>);
}
