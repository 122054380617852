import getConfig from 'next/config';

import { Storage, StorageOptions } from '@google-cloud/storage';

import {
  City,
  Street,
  ActiveTenantCount,
  AverageRentoutDay,
  PostalCode,
  ListingsSearchSitemapItem,
} from '@kamernet/core/ApiClient/Kamernet';
import { logger } from '@kamernet/core/Logger';

import { ServerDataCache } from './types';

const { serverRuntimeConfig } = getConfig();

let serverDataCache: ServerDataCache;

export async function initializeServerDataCache(): Promise<void> {
  if (serverDataCache) {
    logger.warn('initializeServerDataCache is called more than once');
    return;
  }

  const downloadStartTime = Date.now();
  const storage = new Storage({
    retryOptions: {
      maxRetries: 5,
    },
    credentials: JSON.parse(
      serverRuntimeConfig.GCLOUD_STORAGE_CREDENTIALS,
    ) as StorageOptions['credentials'],
  });

  const bucket = storage.bucket(
    serverRuntimeConfig.GCLOUD_STORAGE_SERVER_DATA_CACHE_BUCKET_NAME,
  );

  const [
    citiesRaw,
    streetsRaw,
    postalCodesRaw,
    activeTenantsCountRaw,
    averageRentoutDaysRaw,
    listingsSearchSitemapRaw,
  ] = await Promise.all([
    bucket
      .file(
        serverRuntimeConfig.GCLOUD_STORAGE_SERVER_DATA_CACHE_CITIES_FILE_NAME,
      )
      .download(),
    bucket
      .file(
        serverRuntimeConfig.GCLOUD_STORAGE_SERVER_DATA_CACHE_STREETS_FILE_NAME,
      )
      .download(),
    bucket
      .file(
        serverRuntimeConfig.GCLOUD_STORAGE_SERVER_DATA_CACHE_POSTAL_CODES_FILE_NAME,
      )
      .download(),
    bucket
      .file(
        serverRuntimeConfig.GCLOUD_STORAGE_SERVER_DATA_CACHE_ACTIVE_TENANTS_COUNT_FILE_NAME,
      )
      .download(),
    bucket
      .file(
        serverRuntimeConfig.GCLOUD_STORAGE_SERVER_DATA_CACHE_AVERAGE_RENTOUT_DAYS_FILE_NAME,
      )
      .download(),
    bucket
      .file(
        serverRuntimeConfig.GCLOUD_STORAGE_SERVER_DATA_CACHE_LISTINGS_SEARCH_SITEMAP_FILE_NAME,
      )
      .download(),
  ]);

  const downloadEndTime = Date.now();
  const parseStartTime = Date.now();

  const cities = JSON.parse(citiesRaw.toString()) as City[];
  const streets = JSON.parse(streetsRaw.toString()) as Street[];

  const cityById: Record<number, City> = {};
  for (const city of cities) {
    cityById[city.id] = city;
  }

  const streetById: Record<number, Street> = {};
  for (const street of streets) {
    streetById[street.id] = street;
  }

  serverDataCache = {
    cities,
    cityById,

    streets,
    streetById,

    postalCodes: JSON.parse(postalCodesRaw.toString()) as PostalCode[],

    activeTenantsCount: JSON.parse(
      activeTenantsCountRaw.toString(),
    ) as ActiveTenantCount[],

    averageRentoutDays: JSON.parse(
      averageRentoutDaysRaw.toString(),
    ) as AverageRentoutDay[],

    listingsSearchSitemap: JSON.parse(
      listingsSearchSitemapRaw.toString(),
    ) as ListingsSearchSitemapItem[],
  };

  const parseEndTime = Date.now();

  logger.info({
    message: 'Downloaded server data cache from gcp',
    citiesCount: serverDataCache.cities.length,
    streetsCount: serverDataCache.streets.length,
    postalCodesCount: serverDataCache.postalCodes.length,
    activeTenantsCount: serverDataCache.activeTenantsCount.length,
    averageRentoutDaysCount: serverDataCache.averageRentoutDays.length,
    listingSearchSitemapCount: serverDataCache.listingsSearchSitemap.length,
    downloadTime: downloadEndTime - downloadStartTime,
    parseTime: parseEndTime - parseStartTime,
  });
}

export function getServerDataCache(): ServerDataCache {
  if (typeof window !== 'undefined') {
    throw new Error('ServerDataCache is not available in the browser');
  }

  return serverDataCache;
}
