/**
 *
 */
export enum NumberofIdealTenants {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  MoreThanFivePersons = 6,
}
