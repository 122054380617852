export interface Mixpanel {
  reset: () => void;
}

export interface AnalyticsGlobalTypes {
  mixpanel?: Mixpanel;
  dataLayer?: object[];
  ga?: (event: string, payload: string) => void;
  hj?: (action: string, userId?: number | string, payload?: object) => void;
}

export interface AnalyticsPageProps {
  analytics?: {
    name?: AnalyticsPageName;
    category?: AnalyticsPageCategory;
    properties?: Record<string, unknown>;
  };
}

// previously `MixPanelPages`
export enum AnalyticsPageName {
  SearchLivingPlace = 'living place',
  SearchRoom = 'Room',
  SearchApartment = 'Apartment',
  SearchStudio = 'Studio',
  SearchAntisquat = 'Antisquat',
  TenantPremiumProducts = 'Premium page',
  TenantBillingDetailFifteenDays = 'Premium 15 days - subscription',
  TenantBillingDetailOneMonth = 'Premium 1 month - subscription',
  TenantBillingDetailThreeMonthsNoRenewal = 'Premium 3 months - no renewal',
  LandlordPremiumProducts = 'Tenant search',
  LandlordBillingDetailOneMonth = 'Premium 1 month - Landlords',
  LandlordBillingDetailFifteenDays = 'Premium 15 days - Landlords',
  LCFStep = 'listing_creation_flow_step',
  LCFContinue = 'listing_creation_flow_continue',
  LCFStart = 'listing_creation_flow_start',
  LCFProducts = 'listing_creation_flow_products',
  MyConversationsAll = 'All messages',
  MyConversationsUnreplied = 'Unreplied conversations',
  MyConversationsFavorite = 'Favorite messages',
  MyConversationsActiveListings = 'Messages active adverts',
  MyConversationsInactiveListings = 'Messages inactive adverts',
  MyConversationsTrash = 'Trash',
  Other = 'Other',
}

export enum AnalyticsPageCategory {
  SearchResults = 'Search results page',
  TenantPremium = 'Tenant premium page',
  LandlordPremium = 'Landlord premium page',
  TenantSearch = 'Tenant search',
  LDP = 'listing detail page',
  LCF = 'listing_creation_flow',
  Other = 'Other',
}

// previously `MixPanelCookies`
export enum AnalyticsCookie {
  FirstSource = 'kn-mp-source',
}

export enum AnalyticsSession {
  CurrentSource = 'kn-mp-source',
}

// previously `MixPanelEvent`
export enum AnalyticsEvent {
  PageViewed = 'PageViewed',
  Purchase = 'purchase',
  UnlessExperimentStarted = 'Experiment Unless started',
  SearchListingClicked = 'search listing clicked',
  SearchFilterApplied = 'search filter applied',
  SearchWithCity = 'Search with city',
  ClickedSearchButton = 'clicked search button',
  ClickedChangeView = 'clicked to change view on search',
  SearchSortApplied = 'search sort applied',
  ClickedCreateSearchAlert = 'clicked create search alert',
  SearchAlertSet = 'search alert set',
  ClickedGalleryLDP = 'clicked gallery slideshow',
  ClickedShowMoreLDP = 'clicked show more/less description',
  ListingFavorited = 'listing favorited',
  ExperimentStarted = 'Experiment started',
  LCFListingTypeSelected = 'Selected a property type in listing creation',
  LCFListingCreated = 'create_listing',
  MyConversationsMarkRead = 'read conversation',
  MyConversationsMarkUnread = 'unread conversation',
  MyConversationsMarkFavorite = 'star conversation',
  MyConversationsMarkUnfavorite = 'unstar conversation',
  MyConversationsMarkTrash = 'archive conversation',
  MyConversationsMarkRestore = 'unarchive conversation',
  MyConversationsMarkDelete = 'delete conversation',
  MyConversationsClickMatchScoreBadge = 'MSU BadgeClick',
  MyConversationsSortBy = 'MSU Sortby',
}

// previously `MixpanelUserProperties`
export type AnalyticsUserProperties = {
  source?: string;
  currentSource?: string;
  path?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
  utm_campaign?: string;
  subscription_type?: string;
};
