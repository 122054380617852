export enum GeneralErrorCode {
  UNKNOWN = 'unknown',

  INVALID_REQUEST = 'invalid_request',

  http_400 = 'http_400',

  http_401 = 'http_401',

  http_403 = 'http_403',

  http_404 = 'http_404',

  http_500 = 'http_500',

  http_501 = 'http_501',

  http_502 = 'http_502',
}
export enum CustomTags {
  KN_Module = 'KN_Module',
}

export enum ErrorModules {
  Payment = 'Payment',
  Home = 'Home',
  Listing = 'Listing',
  AppBootstrap = 'AppBootstrap',
  InitFeatureFlags = 'InitFeatureFlags',
  InitAuthState = 'InitAuthState',
  AnonymousPSA = 'AnonymousPSA',
  BootstrapServer = 'BootstrapServer',
  InitAppCookies = 'InitAppCookies',
  Sitemap = 'Sitemap',
  LCF = 'LCF',
  MyDashboard = 'MyDashboard',
}
