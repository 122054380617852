import React from 'react';

import getConfig from 'next/config';
import Script from 'next/script';

import { CredentialResponse } from 'google-one-tap';

import { AppServicesContext } from '@kamernet/core/AppServices';
import { logger } from '@kamernet/core/Logger';

const { publicRuntimeConfig } = getConfig();

export const GoogleOneTap = () => {
  const googleClientId = publicRuntimeConfig.GOOGLE_SIGN_IN_CLIENT_ID;
  const { apiClient, featureFlags } = AppServicesContext.useContext();
  const useIDP = featureFlags.use_idp === 'on';

  const onCredentialReceive = React.useCallback(
    async ({ credential }: CredentialResponse) => {
      if (useIDP) {
        await apiClient.other.oauthSigninGoogleOneTap({
          credential,
        });
      } else {
        await apiClient.auth.signinGoogleOneTapLegacy({
          googleOneTapCredential: { credential },
        });
      }

      const continueUrl = new URL(window.location.href);
      continueUrl.searchParams.set('signedin', 'true');
      window.location.assign(continueUrl);
    },
    [apiClient, useIDP],
  );

  if (!googleClientId) {
    return null;
  }

  return (
    <Script
      src="https://accounts.google.com/gsi/client"
      defer
      onLoad={() => {
        if (window.google && window.google.accounts) {
          window.google.accounts.id.initialize({
            client_id: googleClientId,
            callback: onCredentialReceive,
          });
          window.google.accounts.id.prompt(notification => {
            if (
              notification.isNotDisplayed() ||
              notification.isSkippedMoment()
            ) {
              logger.info('Google one tap opted out');
            }
          });
        }
      }}
    />
  );
};
