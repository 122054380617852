export class OAuthTokenStore {
  static #accessToken: string | null = null;

  static getAccessToken() {
    return this.#accessToken;
  }

  static setAccessToken(accessToken: string | null) {
    this.#accessToken = accessToken;
  }
}
