import { ApiClientBase } from '@kamernet/core/ApiClient/ApiClientBase';
import { Location } from '@kamernet/core/ApiClient/Kamernet';

import { LOCATION_SUGGESTIONS_API_ROUTE } from '.';

export function locationSuggestions(
  this: ApiClientBase,
  query: string,
  init?: RequestInit | undefined,
) {
  return this.handleResponse<Location[]>(
    fetch(
      `${this.kamernetWebOrigin}${LOCATION_SUGGESTIONS_API_ROUTE}?query=${query}`,
      {
        method: 'GET',
        headers: { ...this.headers },
        ...init,
      },
    ),
  );
}
