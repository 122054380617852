import { OwnerTypeID, RoomTypeID } from '@kamernet/core/ApiClient/Kamernet';

const homeType: Record<RoomTypeID, string> = {
  [RoomTypeID.Room]: 'Room',
  [RoomTypeID.Apartment]: 'Apartment',
  [RoomTypeID.Studio]: 'Studio',
  [RoomTypeID.Antikraak]: 'Anti-squat',
  [RoomTypeID.StudentResidence]: 'Student Residence',
};

const landlordKind: Record<OwnerTypeID, string> = {
  [OwnerTypeID.PrivateOwner]: 'PrivateOwner',
  [OwnerTypeID.Roommate]: 'Roommate',
  [OwnerTypeID.RealEstateAgent]: 'RealEstateAgent',
  [OwnerTypeID.Other]: 'Other',
};

export function getLCFListingCreatedEventPayload({
  listingTypeID,
  advertiserID,
  ownerTypeID,
  listingCityName,
  listingID,
}: {
  listingTypeID: RoomTypeID;
  advertiserID: string;
  ownerTypeID: OwnerTypeID;
  listingCityName: string;
  listingID: number;
}) {
  return {
    home_type: homeType[listingTypeID],
    property_type: listingTypeID === RoomTypeID.Room ? 'Room' : 'Entire place',
    landlord_kind: landlordKind[ownerTypeID],
    listing_city: listingCityName,
    listing_id: listingID.toString(),
    advertiser_id: advertiserID,
  };
}
