import { Locale } from '@kamernet/core/Intl/types';
import { logger } from '@kamernet/core/Logger';

const localeCache: Partial<Record<Locale, Record<string, string>>> = {};

export async function loadLocale(
  locale: Locale,
): Promise<Record<string, string>> {
  if (localeCache[locale]) {
    return localeCache[locale]!;
  }

  logger.info(`loading locale ${locale}`);

  const data = await import(`./${locale}.json`);

  localeCache[locale] = data;

  return data;
}
