/**
 *
 */
export enum PaymentStatusId {
  StartedNotSent = -1,
  Processing = 1,
  Paid = 2,
  Error = 4,
  FailedPemanently = 5,
  PendingAndSuccess = 6,
  Refused = 9,
  Cancelled = 10,
  Refund = 12,
  Chargeback = 16,
  Failed = 18,
  ValidationFailure = 19,
  TechnicalFailure = 20,
  PendingInput = 21,
}
