/**
 *
 */
export enum RoomTypeID {
  Room = 1,
  Apartment = 2,
  Studio = 4,
  Antikraak = 8,
  StudentResidence = 16,
}
