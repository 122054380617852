import { ApiClientBase } from '@kamernet/core/ApiClient/ApiClientBase';

import { GoogleOneTapCredential } from '@kamernet/core/ApiClient/Kamernet';
import { OAUTH_SIGNIN_GOOGLE_ONE_TAP_API_ROUTE } from '.';

export function oauthSigninGoogleOneTap(
  this: ApiClientBase,
  payload: GoogleOneTapCredential,
) {
  return this.handleResponse<void>(
    fetch(`${this.kamernetWebOrigin}${OAUTH_SIGNIN_GOOGLE_ONE_TAP_API_ROUTE}`, {
      method: 'POST',
      headers: { ...this.headers, ...ApiClientBase.HEADER_CONTENT_TYPE_JSON },
      body: JSON.stringify(payload),
    }),
  );
}
