import { DeviceType } from '@kamernet/utilities/UserAgent/types';

import { DesignTokens } from './theme.design';

export function getSSRScreenWidth(deviceType: DeviceType) {
  switch (deviceType) {
    case 'mobile':
      return DesignTokens.breakpoint.sm - 1;
    case 'tablet':
      return DesignTokens.breakpoint.md - 1;
    case 'desktop':
    default:
      return DesignTokens.breakpoint.lg - 1;
  }
}
