/* eslint-disable max-classes-per-file */
import { Path } from 'react-hook-form';
import { ApiClientError } from './ApiClientError';

export class KamernetError<
  DataType extends object = object,
> extends ApiClientError<DataType> {
  override name = 'KamernetError';
}

export class KamernetValidationError<PayloadType> extends KamernetError<{
  errors: {
    key: Path<PayloadType>;
  }[];
}> {
  override name = 'KamernetValidationError';
}
