// Will create or overwrite an existing cookie with the same name
export function setCookieValue(
  name: string,
  value: string,
  expiresInDays: number,
): void {
  const encodedValue = encodeURIComponent(value);
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + expiresInDays);
  const expires = expirationDate.toUTCString();
  document.cookie = `${name}=${encodedValue}; expires=${expires}; path=/`;
}
