import React from 'react';

import getConfig from 'next/config';

import { CookieConsentClient } from '@hbf/cookie-consent';

import { initAnalytics } from '@kamernet/core/Analytics/RudderStack/initAnalytics';
import { ApiClient } from '@kamernet/core/ApiClient';
import { OAuthTokenStore } from '@kamernet/core/Auth/OAuthTokenStore';
import { FeatureFlags } from '@kamernet/core/FeatureFlags/types';
import { isFeatureFlagEnabled } from '@kamernet/core/FeatureFlags/utilities';

import { AppServicesContext } from './AppServicesContext';
import { IAppServices } from './types';

const { publicRuntimeConfig } = getConfig();

export const AppServicesProvider = ({
  children,
  featureFlags,
}: {
  children: React.ReactNode;
  featureFlags: FeatureFlags;
}) => {
  const isIDPEnabled = isFeatureFlagEnabled(featureFlags.use_idp);

  const apiClient = React.useMemo(() => {
    const instance = new ApiClient();
    if (isIDPEnabled) {
      instance.withAuthorizationHeaderGetter(() => {
        const accessToken = OAuthTokenStore.getAccessToken();
        return accessToken ? `Bearer ${accessToken}` : undefined;
      });
    }
    return instance;
  }, [isIDPEnabled]);

  const cookieConsentClient = React.useMemo(() => {
    return new CookieConsentClient({
      options: {
        domainKey: publicRuntimeConfig.ONE_TRUST_DOMAIN_KEY,
        withGoogleConsent: true,
      },
    });
  }, []);

  React.useEffect(() => {
    initAnalytics();

    cookieConsentClient.init().catch(() => {
      // Ignore
    });
  }, [cookieConsentClient]);

  const value = React.useMemo(
    () =>
      ({
        apiClient,
        featureFlags,
        cookieConsentClient,
      } as IAppServices),
    [apiClient, cookieConsentClient, featureFlags],
  );

  return (
    <AppServicesContext.Provider value={value}>
      {children}
    </AppServicesContext.Provider>
  );
};
