import type {
  CreateListingRequest,
  CreateListingResponse,
  GetDraftsResponse,
  GetLCFCompletionStatusResponse,
  GetLCFStudentHouseInfo,
  IsAddressReadonlyResponse,
  ListingDetailsResponse,
  UploadLCFPhotoResponse,
} from '../models/index';
import * as runtime from '../runtime';

export interface GetCompletionStatusRequest {
  listingID: number;
}

export interface GetLatestListingDetailsRequest {
  listingID: number;
}

export interface GetStudentHouseInfoRequest {
  listingID: number;
}

export interface IsAddressReadonlyRequest {
  listingID: number;
}

export interface ReactivateRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitAddressRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitAvailabilityRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitCurrentResidentsRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitDetailsRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitFinalRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitListingTypeRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitMediaRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitOwnerTypeRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitPreferredTenantRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitRentalPriceRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitStudentHouseRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitSurfaceFurnishingRequest {
  createListingRequest: CreateListingRequest;
}

export interface SubmitTitleDescriptionRequest {
  createListingRequest: CreateListingRequest;
}

export interface UploadPhotoRequest {
  file: Blob;
}

/**
 *
 */
export class LCFApi extends runtime.BaseAPI {
  /**
   */
  async getCompletionStatusRaw(
    requestParameters: GetCompletionStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetLCFCompletionStatusResponse>> {
    if (requestParameters.listingID == null) {
      throw new runtime.RequiredError(
        'listingID',
        'Required parameter "listingID" was null or undefined when calling getCompletionStatus().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.listingID != null) {
      queryParameters.listingID = requestParameters.listingID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/supplyflow/cra-listing-status`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get lcf completion status
   */
  async getCompletionStatus(
    requestParameters: GetCompletionStatusRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetLCFCompletionStatusResponse> {
    const response = await this.getCompletionStatusRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getDraftsRaw(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetDraftsResponse>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/supplyflow/cra-draft-list-status`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get drafts
   */
  async getDrafts(
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetDraftsResponse> {
    const response = await this.getDraftsRaw(initOverrides);
    return response.value();
  }

  /**
   */
  async getLatestListingDetailsRaw(
    requestParameters: GetLatestListingDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ListingDetailsResponse>> {
    if (requestParameters.listingID == null) {
      throw new runtime.RequiredError(
        'listingID',
        'Required parameter "listingID" was null or undefined when calling getLatestListingDetails().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.listingID != null) {
      queryParameters.listingID = requestParameters.listingID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/supplyflow/cra-latest-listing-details`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get latest listing details
   */
  async getLatestListingDetails(
    requestParameters: GetLatestListingDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ListingDetailsResponse> {
    const response = await this.getLatestListingDetailsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getStudentHouseInfoRaw(
    requestParameters: GetStudentHouseInfoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetLCFStudentHouseInfo>> {
    if (requestParameters.listingID == null) {
      throw new runtime.RequiredError(
        'listingID',
        'Required parameter "listingID" was null or undefined when calling getStudentHouseInfo().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.listingID != null) {
      queryParameters.listingID = requestParameters.listingID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/supplyflow/cra-latest-listing-studenthouse-details`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get student house info
   */
  async getStudentHouseInfo(
    requestParameters: GetStudentHouseInfoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetLCFStudentHouseInfo> {
    const response = await this.getStudentHouseInfoRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async isAddressReadonlyRaw(
    requestParameters: IsAddressReadonlyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<IsAddressReadonlyResponse>> {
    if (requestParameters.listingID == null) {
      throw new runtime.RequiredError(
        'listingID',
        'Required parameter "listingID" was null or undefined when calling isAddressReadonly().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.listingID != null) {
      queryParameters.listingID = requestParameters.listingID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/supplyflow/cra-is-address-readonly`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Is address readonly
   */
  async isAddressReadonly(
    requestParameters: IsAddressReadonlyRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<IsAddressReadonlyResponse> {
    const response = await this.isAddressReadonlyRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async reactivateRaw(
    requestParameters: ReactivateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling reactivate().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-reactivate`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Reactivate listing
   */
  async reactivate(
    requestParameters: ReactivateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.reactivateRaw(requestParameters, initOverrides);
    return response.value();
  }

  /**
   */
  async submitAddressRaw(
    requestParameters: SubmitAddressRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitAddress().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-address`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit address
   */
  async submitAddress(
    requestParameters: SubmitAddressRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitAddressRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitAvailabilityRaw(
    requestParameters: SubmitAvailabilityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitAvailability().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-availability`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit availability
   */
  async submitAvailability(
    requestParameters: SubmitAvailabilityRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitAvailabilityRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitCurrentResidentsRaw(
    requestParameters: SubmitCurrentResidentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitCurrentResidents().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-currentresidents`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit current residents
   */
  async submitCurrentResidents(
    requestParameters: SubmitCurrentResidentsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitCurrentResidentsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitDetailsRaw(
    requestParameters: SubmitDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitDetails().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-details`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit details
   */
  async submitDetails(
    requestParameters: SubmitDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitDetailsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitFinalRaw(
    requestParameters: SubmitFinalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitFinal().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-complete-create-advert`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Finalize the listing creation flow
   */
  async submitFinal(
    requestParameters: SubmitFinalRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitFinalRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitListingTypeRaw(
    requestParameters: SubmitListingTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitListingType().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-roomtype`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit listing type
   */
  async submitListingType(
    requestParameters: SubmitListingTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitListingTypeRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitMediaRaw(
    requestParameters: SubmitMediaRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitMedia().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-create-media`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit media
   */
  async submitMedia(
    requestParameters: SubmitMediaRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitMediaRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitOwnerTypeRaw(
    requestParameters: SubmitOwnerTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitOwnerType().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-ownertype`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit owner type
   */
  async submitOwnerType(
    requestParameters: SubmitOwnerTypeRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitOwnerTypeRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitPreferredTenantRaw(
    requestParameters: SubmitPreferredTenantRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitPreferredTenant().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-preferredtenant`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit preferred tenant
   */
  async submitPreferredTenant(
    requestParameters: SubmitPreferredTenantRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitPreferredTenantRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitRentalPriceRaw(
    requestParameters: SubmitRentalPriceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitRentalPrice().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-rentalprice`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit rental price
   */
  async submitRentalPrice(
    requestParameters: SubmitRentalPriceRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitRentalPriceRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitStudentHouseRaw(
    requestParameters: SubmitStudentHouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitStudentHouse().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-studenthouse`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit student house
   */
  async submitStudentHouse(
    requestParameters: SubmitStudentHouseRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitStudentHouseRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitSurfaceFurnishingRaw(
    requestParameters: SubmitSurfaceFurnishingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitSurfaceFurnishing().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-surface-furnishing`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit surface and furnishing
   */
  async submitSurfaceFurnishing(
    requestParameters: SubmitSurfaceFurnishingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitSurfaceFurnishingRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async submitTitleDescriptionRaw(
    requestParameters: SubmitTitleDescriptionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<CreateListingResponse>> {
    if (requestParameters.createListingRequest == null) {
      throw new runtime.RequiredError(
        'createListingRequest',
        'Required parameter "createListingRequest" was null or undefined when calling submitTitleDescription().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/supplyflow/cra-title-description`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.createListingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Submit title and description
   */
  async submitTitleDescription(
    requestParameters: SubmitTitleDescriptionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<CreateListingResponse> {
    const response = await this.submitTitleDescriptionRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async uploadPhotoRaw(
    requestParameters: UploadPhotoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<UploadLCFPhotoResponse>> {
    if (requestParameters.file == null) {
      throw new runtime.RequiredError(
        'file',
        'Required parameter "file" was null or undefined when calling uploadPhoto().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const consumes: runtime.Consume[] = [
      { contentType: 'multipart/form-data' },
    ];
    // @ts-ignore: canConsumeForm may be unused
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: unknown): unknown };
    let useForm = false;
    // use FormData to transmit files using content-type "multipart/form-data"
    useForm = canConsumeForm;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.file != null) {
      formParams.append('file', requestParameters.file as unknown);
    }

    const response = await this.request(
      {
        path: `/supplyflow/cra-upload-photo`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Upload photo
   */
  async uploadPhoto(
    requestParameters: UploadPhotoRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<UploadLCFPhotoResponse> {
    const response = await this.uploadPhotoRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }
}
