import React from 'react';

import getConfig from 'next/config';
import Head from 'next/head';
import Script from 'next/script';

const { publicRuntimeConfig } = getConfig();

export const RecaptchaEnterprise = () => {
  const keyId = publicRuntimeConfig.RECAPTCHA_ENTERPRISE_KEY_ID;

  if (!keyId) {
    return null;
  }

  const src = `https://www.google.com/recaptcha/enterprise.js?render=${keyId}`;

  return (
    <React.Fragment>
      <Head>
        <style>{`.grecaptcha-badge { visibility: hidden; }`}</style>
      </Head>
      <Script src={src} async defer />
    </React.Fragment>
  );
};
