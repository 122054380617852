/**
 *
 */
export enum RentalPriceID {
  Eur0 = 0,
  Eur100 = 1,
  Eur200 = 2,
  Eur300 = 3,
  Eur400 = 4,
  Eur500 = 5,
  Eur600 = 6,
  Eur700 = 7,
  Eur800 = 8,
  Eur900 = 9,
  Eur1000 = 10,
  Eur1100 = 11,
  Eur1200 = 12,
  Eur1300 = 13,
  Eur1400 = 14,
  Eur1500 = 15,
  Eur1750 = 16,
  Eur2000 = 17,
  Eur2250 = 18,
  Eur2500 = 19,
  Eur2750 = 20,
  Eur3000 = 21,
  Eur3250 = 22,
  Eur3500 = 23,
  Eur3750 = 24,
  Eur4000 = 25,
  Eur4250 = 26,
  Eur4500 = 27,
  Eur4750 = 28,
  Eur5000 = 29,
  Eur5250 = 30,
  Eur5500 = 31,
  Eur5750 = 32,
  Eur6000 = 33,
  Eur20000 = 34,
}
