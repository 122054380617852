export function getCookieValue(name: string): string | null {
  // Split cookies by ;, even if there is or isn't a space after the ;
  const cookieArray = document.cookie.replace(/;\s+/g, ';').split(';');

  for (const cookie of cookieArray) {
    const [cookieName, cookieValue] = cookie.split('=');

    if (cookieName === name && cookieValue !== undefined) {
      return decodeURIComponent(cookieValue);
    }
  }

  return null;
}
