import type {
  DeactivateListingRequest,
  FindListingsResponse,
  FindMapDataResponse,
  FindMapListingPayload,
  FindMapListingResponse,
  GetMyListingsCountResponse,
  GetMyListingsResponse,
  ListingCountResponse,
  ListingDetailsResponse,
  ListingRequest,
  ListingSearchCriteriaResponse,
  MyListingStatus,
  ReportListingPayload,
  ReportListingResponse,
  RoomAdvertUserViewTypeID,
} from '../models/index';
import * as runtime from '../runtime';

export interface DeleteRequest {
  listingID: number;
}

export interface DeactivateRequest {
  deactivateListingRequest: DeactivateListingRequest;
}

export interface FindListingsRequest {
  listingRequest: ListingRequest;
}

export interface FindMapDataRequest {
  listingRequest: ListingRequest;
}

export interface FindMapListingsRequest {
  findMapListingPayload: FindMapListingPayload;
}

export interface GetAverageRentOutDaysRequest {
  cityName?: string;
}

export interface GetListingDetailsRequest {
  source: RoomAdvertUserViewTypeID;
  listingId: number;
  psaIdentifier?: string;
}

export interface GetListingSearchCriteriaRequest {
  psaIdentifier?: string;
}

export interface GetMyListingsRequest {
  pageNo: number;
  rowsPerPage: number;
  listingStatusType: MyListingStatus;
  searchTerm: string;
}

export interface GetMyListingsCountRequest {
  searchTerm: string;
}

export interface ListingCountRequest {
  listingRequest: ListingRequest;
}

export interface MoveUpRequest {
  listingID: number;
}

export interface ReportListingRequest {
  reportListingPayload: ReportListingPayload;
}

/**
 *
 */
export class ListingApi extends runtime.BaseAPI {
  /**
   */
  async deleteRaw(
    requestParameters: DeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.listingID == null) {
      throw new runtime.RequiredError(
        'listingID',
        'Required parameter "listingID" was null or undefined when calling delete().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.listingID != null) {
      queryParameters.listingID = requestParameters.listingID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/listing/delete-listing`,
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete listing
   */
  async delete(
    requestParameters: DeleteRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteRaw(requestParameters, initOverrides);
  }

  /**
   */
  async deactivateRaw(
    requestParameters: DeactivateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.deactivateListingRequest == null) {
      throw new runtime.RequiredError(
        'deactivateListingRequest',
        'Required parameter "deactivateListingRequest" was null or undefined when calling deactivate().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/listing/deactivate-listing`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.deactivateListingRequest,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deactivate listing
   */
  async deactivate(
    requestParameters: DeactivateRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deactivateRaw(requestParameters, initOverrides);
  }

  /**
   */
  async findListingsRaw(
    requestParameters: FindListingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FindListingsResponse>> {
    if (requestParameters.listingRequest == null) {
      throw new runtime.RequiredError(
        'listingRequest',
        'Required parameter "listingRequest" was null or undefined when calling findListings().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/listing/findlistings`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.listingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Find listings based on a set of filters
   */
  async findListings(
    requestParameters: FindListingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FindListingsResponse> {
    const response = await this.findListingsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async findMapDataRaw(
    requestParameters: FindMapDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FindMapDataResponse>> {
    if (requestParameters.listingRequest == null) {
      throw new runtime.RequiredError(
        'listingRequest',
        'Required parameter "listingRequest" was null or undefined when calling findMapData().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/listing/findmapdata`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.listingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Find the map markers details such as coordinates and count
   */
  async findMapData(
    requestParameters: FindMapDataRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FindMapDataResponse> {
    const response = await this.findMapDataRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async findMapListingsRaw(
    requestParameters: FindMapListingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<FindMapListingResponse>> {
    if (requestParameters.findMapListingPayload == null) {
      throw new runtime.RequiredError(
        'findMapListingPayload',
        'Required parameter "findMapListingPayload" was null or undefined when calling findMapListings().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/listing/findMaplistings`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.findMapListingPayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Find the Listings based on Map bounds
   */
  async findMapListings(
    requestParameters: FindMapListingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<FindMapListingResponse> {
    const response = await this.findMapListingsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getAverageRentOutDaysRaw(
    requestParameters: GetAverageRentOutDaysRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<number>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.cityName != null) {
      queryParameters.cityName = requestParameters.cityName;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/listing/averagerentoutdays`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<number>(response);
    }
    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Average rent out days for a given city name. if no city name is provided it will return the average out days for the Netherlands
   */
  async getAverageRentOutDays(
    requestParameters: GetAverageRentOutDaysRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<number> {
    const response = await this.getAverageRentOutDaysRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getListingDetailsRaw(
    requestParameters: GetListingDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ListingDetailsResponse>> {
    if (requestParameters.source == null) {
      throw new runtime.RequiredError(
        'source',
        'Required parameter "source" was null or undefined when calling getListingDetails().',
      );
    }

    if (requestParameters.listingId == null) {
      throw new runtime.RequiredError(
        'listingId',
        'Required parameter "listingId" was null or undefined when calling getListingDetails().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.source != null) {
      queryParameters.source = requestParameters.source;
    }

    if (requestParameters.listingId != null) {
      queryParameters.listingId = requestParameters.listingId;
    }

    if (requestParameters.psaIdentifier != null) {
      queryParameters.psaIdentifier = requestParameters.psaIdentifier;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/listing/details`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Gets details for a specific listing.
   */
  async getListingDetails(
    requestParameters: GetListingDetailsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ListingDetailsResponse> {
    const response = await this.getListingDetailsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getListingSearchCriteriaRaw(
    requestParameters: GetListingSearchCriteriaRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ListingSearchCriteriaResponse>> {
    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.psaIdentifier != null) {
      queryParameters.psaIdentifier = requestParameters.psaIdentifier;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/listing/listingsearchcriteria`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get listing search criteria.
   */
  async getListingSearchCriteria(
    requestParameters: GetListingSearchCriteriaRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ListingSearchCriteriaResponse> {
    const response = await this.getListingSearchCriteriaRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getMyListingsRaw(
    requestParameters: GetMyListingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetMyListingsResponse>> {
    if (requestParameters.pageNo == null) {
      throw new runtime.RequiredError(
        'pageNo',
        'Required parameter "pageNo" was null or undefined when calling getMyListings().',
      );
    }

    if (requestParameters.rowsPerPage == null) {
      throw new runtime.RequiredError(
        'rowsPerPage',
        'Required parameter "rowsPerPage" was null or undefined when calling getMyListings().',
      );
    }

    if (requestParameters.listingStatusType == null) {
      throw new runtime.RequiredError(
        'listingStatusType',
        'Required parameter "listingStatusType" was null or undefined when calling getMyListings().',
      );
    }

    if (requestParameters.searchTerm == null) {
      throw new runtime.RequiredError(
        'searchTerm',
        'Required parameter "searchTerm" was null or undefined when calling getMyListings().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.pageNo != null) {
      queryParameters.pageNo = requestParameters.pageNo;
    }

    if (requestParameters.rowsPerPage != null) {
      queryParameters.rowsPerPage = requestParameters.rowsPerPage;
    }

    if (requestParameters.listingStatusType != null) {
      queryParameters.listingStatusType = requestParameters.listingStatusType;
    }

    if (requestParameters.searchTerm != null) {
      queryParameters.searchTerm = requestParameters.searchTerm;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/listing/mylistings`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get my listings
   */
  async getMyListings(
    requestParameters: GetMyListingsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetMyListingsResponse> {
    const response = await this.getMyListingsRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async getMyListingsCountRaw(
    requestParameters: GetMyListingsCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<GetMyListingsCountResponse>> {
    if (requestParameters.searchTerm == null) {
      throw new runtime.RequiredError(
        'searchTerm',
        'Required parameter "searchTerm" was null or undefined when calling getMyListingsCount().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.searchTerm != null) {
      queryParameters.searchTerm = requestParameters.searchTerm;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/listing/mylistings-count`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Get my listings count
   */
  async getMyListingsCount(
    requestParameters: GetMyListingsCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<GetMyListingsCountResponse> {
    const response = await this.getMyListingsCountRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async listingCountRaw(
    requestParameters: ListingCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ListingCountResponse>> {
    if (requestParameters.listingRequest == null) {
      throw new runtime.RequiredError(
        'listingRequest',
        'Required parameter "listingRequest" was null or undefined when calling listingCount().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/listing/count`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.listingRequest,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Listing count based on user applied filters
   */
  async listingCount(
    requestParameters: ListingCountRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ListingCountResponse> {
    const response = await this.listingCountRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }

  /**
   */
  async moveUpRaw(
    requestParameters: MoveUpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.listingID == null) {
      throw new runtime.RequiredError(
        'listingID',
        'Required parameter "listingID" was null or undefined when calling moveUp().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    if (requestParameters.listingID != null) {
      queryParameters.listingID = requestParameters.listingID;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/listing/moveup`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * move up listing
   */
  async moveUp(
    requestParameters: MoveUpRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.moveUpRaw(requestParameters, initOverrides);
  }

  /**
   */
  async reportListingRaw(
    requestParameters: ReportListingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ReportListingResponse>> {
    if (requestParameters.reportListingPayload == null) {
      throw new runtime.RequiredError(
        'reportListingPayload',
        'Required parameter "reportListingPayload" was null or undefined when calling reportListing().',
      );
    }

    const queryParameters: Record<
      string,
      string | string[] | number | boolean
    > = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/listing/abuse`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.reportListingPayload,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response);
  }

  /**
   * Report listing for any scam or other reasons
   */
  async reportListing(
    requestParameters: ReportListingRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ReportListingResponse> {
    const response = await this.reportListingRaw(
      requestParameters,
      initOverrides,
    );
    return response.value();
  }
}
