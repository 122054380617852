export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  ACCEPTED = 202,

  PERMANENT_REDIRECTION = 301,
  FOUND_REDIRECTION = 302,
  NOT_MODIFIED = 304,
  TEMPORARY_REDIRECTION = 307,

  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  GONE = 410,
  PRECONDITION_FAILED = 412,
  ENTITY_TOO_LARGE = 413,
  LOCKED = 423,
  FAILED_DEPENDENCY = 424,
  PRECONDITION_REQUIRED = 428,

  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  SERVICE_UNAVAILABLE = 503,
}
