/* eslint-disable compat/compat */
import { AppContext } from 'next/app';
import getConfig from 'next/config';

import JwtDecode, { JwtPayload } from 'jwt-decode';
import { splitCookiesString, parseString } from 'set-cookie-parser';

import { ApiClient, ApiClientError } from '@kamernet/core/ApiClient';
import { TimeoutError } from '@kamernet/core/ApiClient/Kamernet';
import { readAppCookies } from '@kamernet/core/Cookie/readAppCookies';
import { AppCookieName } from '@kamernet/core/Cookie/types';
import {
  reportError,
  setUserContextForErrorReporting,
} from '@kamernet/core/Errors';
import { CustomTags, ErrorModules } from '@kamernet/core/Errors/types';
import { SessionFeatureFlags } from '@kamernet/core/FeatureFlags/types';
import { Locale } from '@kamernet/core/Intl/types';
import { logger } from '@kamernet/core/Logger';

import { IAuthState } from './types';

const { publicRuntimeConfig } = getConfig();

export async function initAuthServerApp(
  appContext: AppContext,
  sessionFeatureFlags: SessionFeatureFlags,
) {
  let authState: IAuthState = {
    userProfile: null,
    anonymousId: null,
    accessToken: null,
  };

  const req = appContext.ctx.req!;
  const res = appContext.ctx.res!;

  const useIDP = sessionFeatureFlags.use_idp === 'on';

  const apiClient = new ApiClient();

  const responseCookies: Record<string, string> = {};
  const appCookies = readAppCookies(req);
  apiClient.withCookies(appCookies);

  function updateKamernetAndResponseCookies(
    newCookies: string | null | undefined,
  ): void {
    if (!newCookies) {
      return;
    }

    for (const newCookie of splitCookiesString(newCookies)) {
      const parsedNewCookie = parseString(newCookie);
      responseCookies[parsedNewCookie.name] = newCookie;
      appCookies[parsedNewCookie.name] = parsedNewCookie.value;
    }

    apiClient.withCookies(appCookies);
  }

  try {
    // access token init - start
    if (useIDP) {
      let accessToken = appCookies[AppCookieName.OAUTH_ACCESS_TOKEN];
      const refreshToken = appCookies[AppCookieName.OAUTH_REFRESH_TOKEN];

      try {
        const { exp = 0 } = accessToken
          ? JwtDecode<JwtPayload>(accessToken)
          : {};

        const accessTokenExpiresIn = (exp - 400) * 1000 - Date.now(); // try to refresh 400 seconds before it expires

        if (
          appCookies[AppCookieName.OAUTH_REFRESH_TOKEN] &&
          accessTokenExpiresIn < 0
        ) {
          logger.info({
            message: 'initAuthServerApp - performing SSR refresh',
            userAgent: req.headers['user-agent'],
            accessTokenExpiresIn,
            url: req.url,
            anonymousId: appCookies[AppCookieName.ANONYMOUS_ID],
            reqRefreshToken: refreshToken?.slice(refreshToken.length - 10),
            reqAccessToken: accessToken?.slice(accessToken.length - 10),
          });

          const refreshResponse = await apiClient.other.oauthRefreshAccessToken(
            req.headers['user-agent'],
          );

          updateKamernetAndResponseCookies(
            refreshResponse.response.headers.get('Set-Cookie'),
          );

          accessToken = refreshResponse.data.accessToken;
        }
      } catch (error) {
        if (error instanceof ApiClientError) {
          updateKamernetAndResponseCookies(
            error.response?.headers.get('Set-Cookie'),
          );
        }

        reportError(error as Error, {
          extra: {
            context: 'Failed to perform SSR access token refresh',
            userAgent: req.headers['user-agent'],
          },
          tags: {
            [CustomTags.KN_Module]: ErrorModules.InitAuthState,
          },
        });
      }

      authState.accessToken = accessToken ?? null;
      apiClient.withAuthorizationHeaderGetter(() => {
        return authState.accessToken
          ? `Bearer ${authState.accessToken}`
          : undefined;
      });
    }
    // access token init - end

    // user profile init - start
    const userProfileResponse = await apiClient.user.getUserProfileRaw({
      signal: AbortSignal.timeout(
        publicRuntimeConfig.API_CLIENT_FETCH_TIMEOUT_MILISECONDS,
      ),
    });

    updateKamernetAndResponseCookies(
      userProfileResponse.raw.headers.get('Set-Cookie'),
    );

    const userProfile = await userProfileResponse.value();
    authState.userProfile = userProfile.userid ? userProfile : null;
    // user profile init - end

    authState.anonymousId = appCookies[AppCookieName.ANONYMOUS_ID] ?? null;

    res.appendHeader('Set-Cookie', Object.values(responseCookies));

    if (authState.userProfile) {
      setUserContextForErrorReporting(
        authState.userProfile,
        appContext.router.locale as Locale,
        authState.anonymousId,
      );
    }
  } catch (error) {
    authState = {
      userProfile: null,
      anonymousId: null,
      accessToken: null,
    };

    if (error instanceof TimeoutError) {
      throw new Error('Failed to fetch user profile due to timeout error');
    }

    reportError(error as Error, {
      extra: {
        context: 'Failed to init auth state',
        request: req,
      },
      tags: {
        [CustomTags.KN_Module]: ErrorModules.InitAuthState,
      },
    });
  }

  Object.assign(req, {
    authState,
    apiClient,
  });

  return authState;
}
